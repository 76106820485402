import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import {
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
// import { Edit } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialServices from "api/medical-materials";
import WarehouseServices from "api/warehouse";
import moment from "moment";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import { formatCurrencyVND } from "utils/helper";
import Create from "./create";

const Warehouse = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [materials, setMaterials] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");

  const columns = [
    { field: "id", headerName: "#ID", width: 30 },
    {
      field: "name",
      headerName: "Tên",
      width: 170,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.name}</span>;
      },
    },
    {
      field: "type",
      headerName: "Loại",
      width: 110,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.type}</span>;
      },
    },
    {
      field: "description",
      headerName: "Ghi chú",
      width: 170,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.description}</span>;
      },
    },
    {
      field: "receivedPrice",
      headerName: "Giá nhập",
      width: 120,
      renderCell: (params) => {
        return <span>{formatCurrencyVND(params.row.receivedPrice)}</span>;
      },
    },
    {
      field: "receivedQuantity",
      headerName: "Số lượng",
      width: 110,
      renderCell: (params) => {
        return <span>{params.row.receivedQuantity}</span>;
      },
    },
    {
      field: "quantityPerUnit",
      headerName: "Số lượng mỗi đơn vị",
      width: 185,
    },
    {
      field: "unit",
      headerName: "Đơn vị",
      width: 185,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.unit}</span>;
      },
    },
    {
      field: "receivedDate",
      headerName: "Ngày nhập",
      width: 100,
      renderCell: (params) => {
        return (
          <span>{moment(params.row.receivedDate).format("DD-MM-YYYY")}</span>
        );
      },
    },
    {
      field: "edit",
      headerName: "Sửa",
      width: 100,
      renderCell: (params) => {
        return (
          <Button onClick={() => modalConform(params?.row)} className="w-full">
            <Delete />
          </Button>
        );
      },
    },
  ];

  const handleShowDrawer = () => {
    setForm({});
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      receivedPrice: Number(form.receivedPrice),
      quantityPerUnit: Number(form.quantityPerUnit),
      [name]: value,
    }));
  };

  const handleFormSubmit = async (id) => {
    if (
      (!form.medicalMaterialName, !form.receivedPrice, !form.quantityPerUnit)
    ) {
      alert("Vui lòng nhập đủ trường!");
      return;
    }
    try {
      const date = new Date(form?.receivedDate);
      const isoDateString = date.toISOString();
      const payload = {
        ...form,
        receivedDate: isoDateString,
      };
      await WarehouseServices.createWarehouses(payload);
      setShowDrawerCreate(false);
      setForm({});
      fetchWarehouses();
      dispatch(open({ isNoti: true, message: "Tạo vật tư thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitDelete = async () => {
    try {
      await WarehouseServices.deleteWarehouses(id);
      dispatch(open({ isNoti: true, message: "Xoá vật tư thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    } finally {
      fetchWarehouses();
      setIsOpen(false);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await MaterialServices.getMaterials();
      const formatArray = response.data.data.map((item) => ({
        id: item?.id,
        label: item.name,
        value: item,
      }));
      setMaterials(formatArray);
    } catch (error) {
      console.log(error);
    }
  };

  const modalConform = (data) => {
    setIsOpen(true);
    setId(data?.id);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    fetchMaterials();
  }, [search]);

  const fetchWarehouses = async () => {
    try {
      const response = await WarehouseServices.getWarehouses({
        name: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWarehouses();
  }, [search]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý kho
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm kho
        </Button>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm thuốc và vật tư"
          placeholder="Tên"
          className="w-[300px]"
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin kho
        </Typography>
        <Create
          materials={materials}
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="w-[600px]">
          {"Xác nhận xoá"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn chắc chắn
            <span className="text-red-500 px-2">Xoá</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Huỷ bỏ</Button>
          <Button onClick={() => handleFormSubmitDelete(id)} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>

      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Warehouse;
