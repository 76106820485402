/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatCurrencyVND } from "utils/helper";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    border: "1px solid #d8d8d8",
    background: "#f3f3f3",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
}));

const tableHeader = [
  { label: "STT", data: "" },
  { label: "Nội dung", data: "medicalMaterialName" },
  // { label: "SL", data: "quantityPerUnit" },
  // { label: "Đơn giá", data: "quantityPerUnit" },
  // { label: "Ghi chú", data: "description" },
  { label: "Thành tiền", data: "total" },
];

const TableDetail = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((cell) => (
                <TableCell key={cell.data} className={classes.tableHeaderCell}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <TableRow className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>1</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      Khám + tư vấn
                    </Typography>
                  </Grid>
                </TableCell>

                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(data?.consultationFee)}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>2</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>Thủ thuật</Typography>
                  </Grid>
                </TableCell>

                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(data?.totalFeeProcedures)}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>3</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>Vật tư</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(data?.totalFeeEquiments)}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>4</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>Kê đơn</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(data?.totalFeeDrugs)}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>5</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>Khác</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container></Grid>
                </TableCell>
              </TableRow>
            </>
            <TableRow className="border">
              <TableCell className="border">
                <Grid container>
                  <Typography className={`${classes.name} !font-bold`}>
                    {"Tổng tiền"}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className="border">
                <Grid container>
                  <Typography
                    className={`${classes.name} !font-bold`}
                  ></Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Typography className={classes.name}>
                    <Typography className={`${classes.name} !font-bold`}>
                      {formatCurrencyVND(data?.totalFee)}
                    </Typography>
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className="border">
              <TableCell className="border">
                <Grid container>
                  <Typography className={`${classes.name} !font-bold`}>
                    {"Thành tiền"}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className="border">
                <Grid container>
                  <Typography
                    className={`${classes.name} !font-bold`}
                  >{data?.reason}</Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Typography className={classes.name}>
                    <Typography className={`${classes.name} !font-bold`}>
                      {formatCurrencyVND(data?.finalFee)}
                    </Typography>
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDetail;
