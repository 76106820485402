export function formatCurrencyVND(number) {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return formatter.format(number);
}

export function calculateAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  const yearsDiff = today.getFullYear() - birthDateObj.getFullYear();
  const monthsDiff = today.getMonth() - birthDateObj.getMonth();

  if (
    monthsDiff < 0 ||
    (monthsDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    return yearsDiff - 1;
  }

  return yearsDiff;
}

export function getChangedFields(formData, initialData) {
  const changedFields = {};

  for (const field in formData) {
    if (formData[field] !== initialData[field]) {
      changedFields[field] = formData[field];
    }
  }

  return changedFields;
}

export const convertArray = (array2c, array1c = []) => {
  let newArray = [];
  for (let index = 0; index < array2c?.length; index++) {
    newArray = [...newArray, ...array2c[index]];
  }
  return [...newArray, ...array1c];
};

export function compareObjects(object1, object2) {
  const changedFields = {};

  for (const key in object1) {
    if (object1.hasOwnProperty(key)) {
      if (object1[key] !== object2[key]) {
        changedFields[key] = object2[key];
      }
    }
  }

  return changedFields;
}

export function levenshteinDistance(str1, str2) {
  const len1 = str1.length;
  const len2 = str2.length;

  const matrix = Array.from(Array(len1 + 1), () => Array(len2 + 1).fill(0));

  for (let i = 0; i <= len1; i++) {
    matrix[i][0] = i;
  }
  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  return matrix[len1][len2];
}

export const formatDateToString = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long", // To display the day of the week
  };

  const formatter = new Intl.DateTimeFormat("vi-VN", options);
  const formattedDate = formatter.format(date);

  return formattedDate;
};
