/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import TableDetail from "./tableDetail";
import moment from "moment";
import { ReactComponent as Logo } from "assets/logo.svg";
import { Call, Language, LocationOn } from "@mui/icons-material";

const PrintPrescription = ({ data }) => {
  return (
    <Container>
      <Box p={2} py={16}>
        <Grid container spacing={4} className="flex justify-center gap-10">
          <div className="h-36 w-36">
            <Logo />
          </div>
          <div>
            <p className="flex flex-col text-center">
              <b className="text-[19px]">
                PHÒNG KHÁM CHUYÊN GIA MẠCH MÁU - LỒNG NGỰC
              </b>
              <b className="text-[19px]">TS BS LÊ PHI LONG</b>
            </p>
            <div className="flex flex-col gap-2 mt-2">
              <p className="flex gap-2">
                <span>
                  <LocationOn fontSize="small" />
                </span>
                <span>44 Ký Hoà, Phường 11, Quận 5, TP Hồ Chí Minh</span>
              </p>
              <p className="flex gap-2">
                <span>
                  <Call fontSize="small" />
                </span>
                <span>0567447799</span>
              </p>
              <p className="flex gap-2">
                <span>
                  <Language fontSize="small" />
                </span>
                <span>https://bsphilong.com/</span>
              </p>
            </div>
          </div>
        </Grid>
        <Box className="w-full flex gap-14 justify-between items-start mt-14">
          <Box className="border-r h-full mt-[80px] flex-[25%]">
            <Typography fontSize={17} fontWeight={700} className="w-full">
              PK Chuyên gia nhận tư vấn - Khám và điều trị các bệnh thuộc chuyên
              khoa LNMM:
            </Typography>
            <div className="flex flex-col gap-2 !text-[15px] font-serif">
              <span>
                - Bứu cổ: điều trị bằng sóng cao tẩng, mổ nội soi, mổ hở
              </span>
              <span>- Tăng tiết hồ hôi tay, nách</span>
              <span>- Lõm ngực, ngực ức gà</span>
              <span>- U phổi, U thành ngực - U trung thất</span>
              <span>- Kén phổi - Tràn khí, dịch mảng phổi</span>
              <span>- Bệnh về tĩnh mạch</span>
              <span>- Phỉnh động mạch chủ, động mạch ngoại biên</span>
              <span>
                - Hẹp động mạch cảnh (động mạch não), tắc hẹp ĐM tay, chân
              </span>
              <span>- Dị dạng động - tĩnh mạch, bướu máu</span>
              <span>- Tạo thông động - tĩnh mạch để lọc thận nhân tạo</span>
              <span>- Áp dụng kỹ thuật điều trị hiện đại</span>
              <span>- Phẫu thuật nội soi</span>
              <span>
                - Phẫu thuật Hybrid: kết hợp phẫu thuật với can thiệp nội mạch
              </span>
              <span>- Điều trị sóng cao tần (RFA), Laser</span>
            </div>
          </Box>
          <Box>
            <Typography
              variant="h5"
              fontWeight={700}
              className="w-full text-center mt-4 !-ml-32"
            >
              TOA THUỐC
            </Typography>
            <Grid container spacing={4} m={1}>
              <Grid container spacing={4} mt={4}>
                <Grid xs={6}>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Mã bệnh nhân:
                    </span>
                    <span>{data?.patient?.code}</span>
                  </p>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Bệnh nhân:{" "}
                    </span>
                    <span>{data?.patient?.name}</span>
                  </p>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Địa chỉ:{" "}
                    </span>
                    <span>{data?.patient?.address}</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Ngày sinh:{" "}
                    </span>
                    <span>
                      {moment(data?.patient?.dob).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Số điện thoại:
                    </span>
                    <span>{data?.patient?.phone}</span>
                  </p>
                  <p className="flex gap-4 text-[18px] my-1">
                    <span className="font-bold whitespace-nowrap">
                      Giới tính:
                    </span>
                    <span>{data?.patient?.sex === "Male" ? "Nam" : "Nữ"}</span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} className="!-ml-[50px]">
              <Grid item xs={12}>
                <p className="flex gap-4 text-[18px] my-8">
                  <span className="font-bold whitespace-nowrap">
                    Chẩn đoán:
                  </span>
                  <span>{data?.description}</span>
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid xs={12} mt={5}>
                <TableDetail data={data} />
              </Grid>
            </Grid>
            <Grid container spacing={4} my={4}>
              <Grid xs={12} mt={5}>
                <Typography className="!text-lg !mb-1">
                  <span className="font-bold">Lời dặn</span>:
                  ................................................................................................................................
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} my={4}>
              <Grid xs={12} mt={5}>
                <Typography className="!text-lg !mb-1 flex flex-col">
                  <span className="font-bold">Ngày tái khám:</span>
                  <span>{data?.reexamination}</span>
                  <i className="text-gray-500 text-[15px]">Người bệnh lấy số thứ tự khám tại quầy Đăng ký khám bệnh</i>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} mt={8}>
              <Grid xs={7}></Grid>
              <Grid xs={5} className="text-center">
                <Typography className="!text-xl !mb-1">
                  Ngày {moment(data?.createdAt).format("DD")} tháng{" "}
                  {moment(data?.createdAt).format("MM")} năm 20
                  {moment(data?.createdAt).format("YY")}
                </Typography>
                <Typography className="!text-xl">Bác sĩ điều trị</Typography>
                <Typography className="!text-lg !mt-12"></Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} mt={8}>
              <Grid xs={12}>
                <Typography className="!text-lg !mb-1">
                  Đơn thuốc dùng một lần vui lòng đem theo khi tái khám
                </Typography>
                <Typography className="!text-lg !mb-1">
                  *Khi cần tham vấn thông tin về thuốc đang dùng xin liên lạc
                  trong giờ hành chánh!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PrintPrescription;
