import axios from "../api";

export const createPrescriptions = async (payload) =>
  axios.post("/doctor/prescriptions", payload);

export const getPrescriptions = async (params) =>
  axios.get("/doctor/prescriptions", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const getPrescriptionsCode = async (code) =>
  axios.get(`/doctor/prescriptions/${code}`);

export const getPrescriptionsReceptionist = async (params) =>
  axios.get("/receptionist/prescriptions", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const getPrescriptionsReceptionistCode = async (code) =>
  axios.get(`/receptionist/prescriptions/${code}`);

export const checkPrescriptions = async (code) =>
  axios.patch(`/receptionist/prescriptions/checkout/${code}`);

export const getPrescriptionsAdmin = async (params) =>
  axios.get("/admin/prescriptions", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const previewTotal = async (payload) =>
  axios.post("/doctor/prescriptions/preview", payload);
export const getPrescriptionSimple = async (code) =>
  axios.get(`/doctor/prescriptions/${code}/simple`);

export const getPrescriptionReceip1 = async (code) =>
  axios.get(`/doctor/prescriptions/${code}/receipt1`);

export const getPrescriptionReceip2 = async (code) =>
  axios.get(`/doctor/prescriptions/${code}/receipt2`);

const PrescriptionServices = {
  createPrescriptions,
  getPrescriptions,
  getPrescriptionsCode,
  getPrescriptionsReceptionist,
  getPrescriptionsReceptionistCode,
  checkPrescriptions,
  getPrescriptionsAdmin,
  previewTotal,
  getPrescriptionSimple,
  getPrescriptionReceip1,
  getPrescriptionReceip2,
};

export default PrescriptionServices;
