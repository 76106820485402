import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { sexs } from "constant";

const Create = ({ form, handleSubmitForm, handleChangeInput }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Họ và tên"
            variant="outlined"
            name="name"
            className="w-full"
            value={form.name}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="sex"
            className="w-full"
            disablePortal
            options={sexs}
            inputValue={form?.sex}
            onInputChange={(_, e) =>
              handleChangeInput({ target: { name: "sex", value: e } })
            }
            renderInput={(params) => (
              <TextField {...params} label="Giới Tính" name="sex" />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            label="Số điện thoại"
            variant="outlined"
            name="phone"
            className="w-full"
            value={form.phone}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            placeholder="Ngày sinh"
            type="date"
            variant="outlined"
            name="dob"
            className="w-full"
            value={form.dob}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label="Điạ chỉ"
            variant="outlined"
            name="address"
            className="w-full"
            value={form.address}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextareaAutosize
            placeholder="Tiền sử bệnh"
            name="description"
            className="border border-gray-300 w-full !h-[200px] p-3 rounded-md"
            value={form.description}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitForm}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Create;
