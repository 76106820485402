import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ImagesModal({ open, setOpen, list }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
        <Typography variant="h5" sx={{ mb: 2 }}>
           Ảnh triệu chứng
          </Typography>
          <div className="flex justify-center gap-4 min-h-[500px] min-w-[760px]">
            {list?.length ? list?.map((item) => (
              <div className="w-[340px]">
                <img
                  src={item}
                  style={{
                    marginBottom: 20,
                    aspectRatio: "1/2",
                    objectFit: "contain",
                  }}
                  className="border p-2 w-full"
                />
              </div>
            )) : <p className="w-full h-[50vh] flex justify-center items-center text-gray-500 text-xl">Không có ảnh</p>}
          </div>
         
        </Box>
      </Modal>
    </div>
  );
}
