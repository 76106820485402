/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    border: "1px solid #d8d8d8",
    background: "#f3f3f3",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
}));

const tableHeader = [
  { label: "STT", data: "" },
  { label: "Tên thuốc và vật tư", data: "medicalMaterialName" },
  { label: "Liều lượng - Ghi chú", data: "description" },
  { label: "Số lượng", data: "quantityPerUnit" },
  // { label: "Giá", data: "quantityPerUnit" },
];

const TableDetail = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((cell) => (
                <TableCell key={cell.data} className={classes.tableHeaderCell}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <Typography fontWeight={700} className="p-3">
                Toa thuốc
              </Typography>
              {[...(data?.prescriptionAndMedicalMaterials || [])]?.map(
                (row, i) => (
                  <TableRow key={i} className="border">
                    <TableCell className="border">
                      <Grid container>
                        <Typography className={classes.name}>
                          {i + 1}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border">
                      <Grid container>
                        <Typography className={classes.name}>
                          {row.medicalMaterial?.name ||
                            row.medicalProcedure?.name}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border">
                      <Grid container>
                        <Typography className={classes.name}>
                          ngày uống {row.timesAday} lần, mỗi lần{" "}
                          {row.timesADrink} viên, {row.time} ăn | {row.description}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border">
                      <Grid container>
                        <Typography className={classes.name}>
                          {row.quantityPerUnit} {row?.medicalMaterial?.unit}
                        </Typography>
                      </Grid>
                    </TableCell>
                    {/* <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(row.sellingPricePerUnit)}
                    </Typography>
                  </Grid>
                </TableCell> */}
                  </TableRow>
                )
              )}
            </>
            <>
              <Typography fontWeight={700} className="p-3">
                Thủ thuật
              </Typography>
              {[...(data?.prescriptionAndMedicalProcedures || [])]?.map(
                (row, i) => (
                  <TableRow key={i} className="border">
                    <TableCell className="border bg-white">
                      <Grid container>
                        <Typography className={classes.name}>
                          {i + 1}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border bg-white">
                      <Grid container>
                        <Typography className={classes.name}>
                          {row.medicalMaterial?.name ||
                            row.medicalProcedure?.name}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border bg-white">
                      <Grid container>
                        <Typography className={classes.name}>
                          {row.description}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className="border bg-white">
                      <Grid container>
                        <Typography className={classes.name}>
                          {row.quantityPerUnit} {row?.medicalMaterial?.unit}
                        </Typography>
                      </Grid>
                    </TableCell>
                    {/* <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {formatCurrencyVND(row.sellingPricePerUnit)}
                    </Typography>
                  </Grid>
                </TableCell> */}
                  </TableRow>
                )
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDetail;
