import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { Divider, Drawer, Stack, TextField, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Create from "./create";
import Update from "./update";
import ProcedureServices from "api/medical-procedure";
import moment from "moment";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import { compareObjects, formatCurrencyVND } from "utils/helper";

const defaultValue = {
  description: "",
};

const Procedure = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");
  const [oldData, setOldData] = useState({});
  const dispatch = useDispatch();

  const columns = [
    { field: "id", headerName: "#ID", width: 50 },
    { field: "name", headerName: "Tên", width: 140 },
    {
      field: "currentSellingPricePerUnit",
      headerName: "Giá bán",
      width: 180,
      renderCell: (params) => {
        return (
          <span>
            {formatCurrencyVND(params.row.currentSellingPricePerUnit)}
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Ghi chú",
      width: 380,
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 100,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      field: "edit",
      headerName: "Sửa",
      width: 30,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEdit(params.row)} className="w-full">
            <Edit />
          </Button>
        );
      },
    },
  ];

  const setDefaultForm = () => {
    setForm(defaultValue);
  };

  const handleShowDrawer = () => {
    setDefaultForm();
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleEdit = (data) => {
    setForm(data);
    setOldData(data);
    setShowDrawerUpdate(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const payload = {
        ...form,
        currentSellingPricePerUnit: Number(form.currentSellingPricePerUnit),
      };
      await ProcedureServices.createProcedures(payload);
      setShowDrawerCreate(false);
      setDefaultForm();
      fetchProcedures();
      dispatch(open({ isNoti: true, message: "Thêm thủ thuật thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitUpdate = async () => {
    try {
      const newData = compareObjects(oldData, form);
      const payload = {
        ...newData,
        currentSellingPricePerUnit: newData.currentSellingPricePerUnit
          ? Number(newData.currentSellingPricePerUnit)
          : undefined,
      };
      await ProcedureServices.updateProcedures(form.id, payload);
      setShowDrawerUpdate(false);
      setDefaultForm();
      fetchProcedures();
      dispatch(
        open({ isNoti: true, message: "Cập nhật thủ thuật thành công" })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const fetchProcedures = async () => {
    try {
      const response = await ProcedureServices.getProcedures({
        name: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProcedures();
  }, [search]);

  return (
    <Box p={3}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm Thủ thuật
        </Button>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm thủ thuật"
          placeholder="Tên"
          className="w-[300px]"
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin Thủ thuật
        </Typography>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chỉnh sửa thông tin Thủ thuật
        </Typography>
        <Update
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
        />
      </Drawer>
      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Procedure;
