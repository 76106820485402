/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import PostService from "api/post";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import Create from "./create";
import Update from "./update";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { levenshteinDistance } from "utils/helper";

const Post = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const columns = [
    // { field: "id", headerName: "#ID", width: 20 },
    { field: "title", headerName: "Tiêu đề", width: 150 },
    { field: "author", headerName: "Tên tác giả", width: 180 },
    { field: "description", headerName: "Mô tả", width: 250 },
    {
      field: "avatar",
      headerName: "Ảnh tác giả",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center">
            <img src={params?.row?.avatar} alt="" />
          </div>
        );
      },
    },
    {
      field: "coverImg",
      headerName: "Ảnh bài đăng",
      width: 250,
      renderCell: (params) => {
        return <img src={params?.row?.coverImg} alt="" />;
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 150,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      field: "edit",
      headerName: "Sửa và Xoá",
      width: 110,
      renderCell: (params) => {
        return (
          <div className="flex gap-3 p-2 cursor-pointer">
            <Edit
              color="primary"
              className="hover:bg-blue-200"
              onClick={() => handleEdit(params?.row)}
            />
            <DeleteIcon
              color="primary"
              className="hover:bg-blue-200"
              onClick={() => handleDelete(params?.row?.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleEdit = (data) => {
    setForm({
      ...data,
      createdAt: moment(data?.createdAt).format("YYYY-MM-DD"),
    });
    setShowDrawerUpdate(true);
  };

  const handleDelete = async (id) => {
    const confirmDelete = confirm("Bạn chắc chắn muốn xoá bài đăng ?");
    if (!confirmDelete) return;
    const key = "post";
    const newPosts = rows?.filter((item) => item?.id !== id);
    try {
      const payloadPost = {
        key: key,
        value: {
          [key]: newPosts,
        },
      };
      const payloadPrescriptionsKeyString = {
        key: payloadPost.key,
        value: JSON.stringify(payloadPost.value),
      };
      await PostService.updatePost(key, payloadPrescriptionsKeyString);
      dispatch(open({ isNoti: true, message: "Xoá bài đăng thành công" }));
      fetchPost();
    } catch (error) {
      dispatch(open({ isNoti: true, message: error.response.data.message }));
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const key = "post";
    try {
      const payload = {
        ...form,
        avatar: form.avatar[form.avatar.length - 1],
        coverImg: form.coverImg[form.coverImg.length - 1],
        filePDF: form.filePDF[form.filePDF.length - 1],
      };

      const payloadPost = {
        key: key,
        value: {
          [key]: [...rows, { id: uuidv4(), ...payload }],
        },
      };

      const payloadPrescriptionsKeyString = {
        key: payloadPost.key,
        value: JSON.stringify(payloadPost.value),
      };

      if (rows.length) {
        await PostService.updatePost(key, payloadPrescriptionsKeyString);
      } else {
        await PostService.createPost(payloadPrescriptionsKeyString);
      }
      setShowDrawerCreate(false);
      setForm({});
      fetchPost();
      dispatch(open({ isNoti: true, message: "Tạo bài đăng thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(open({ isNoti: true, message: error.response.data.message }));
    }
  };

  const handleFormSubmitUpdate = async () => {
    const key = "post";
    const oldData = rows.filter((item) => {
      return item.id !== form.id;
    });
    try {
      const payload = {
        ...form,
        avatar: Array.isArray(form.avatar)
          ? form.avatar[form.avatar.length - 1]
          : form.avatar,
        coverImg: Array.isArray(form.coverImg)
          ? form.coverImg[form.coverImg.length - 1]
          : form.coverImg,
        filePDF: Array.isArray(form.filePDF)
          ? form.filePDF[form.filePDF.length - 1]
          : form.filePDF,
      };

      const payloadPost = {
        key: key,
        value: {
          [key]: [...oldData, { ...payload }],
        },
      };

      const payloadPrescriptionsKeyString = {
        key: payloadPost.key,
        value: JSON.stringify(payloadPost.value),
      };

      await PostService.updatePost(key, payloadPrescriptionsKeyString);

      setShowDrawerUpdate(false);
      setForm({});
      fetchPost();
      dispatch(open({ isNoti: true, message: "Cập nhật bài đăng thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(open({ isNoti: true, message: error.response.data.message }));
    }
  };

  const handleShowDrawer = () => {
    setForm({});
    setShowDrawerCreate(!showDrawerCreate);
  };

  const fetchPost = async () => {
    const key = "post";
    try {
      const response = await PostService.getPost(key);
      const data = response.data.value.post?.filter((item) => {
        if (search.title) {
          if (levenshteinDistance(search.title, item.title) <= 7) {
            return item;
          }
        } else {
          return item;
        }
      });
      setRows(data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPost();
  }, [search?.title]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý bài đăng
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm bài đăng
        </Button>
        <TextField
          value={search?.title}
          onChange={(e) => setSearch({ ...search, title: e.target.value })}
          label="Tiêu đề"
          placeholder="Tiêu đề"
          className="w-[300px]"
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 1000,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin bài đăng
        </Typography>
        <Create
          setForm={setForm}
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 1000,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chỉnh sửa thông tin bài đăng
        </Typography>
        <Update
          setForm={setForm}
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
        />
      </Drawer>

      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rowHeight={200}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Post;
