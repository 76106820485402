import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MedicationIcon from "@mui/icons-material/Medication";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BadgeIcon from "@mui/icons-material/Badge";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import AddBoxIcon from "@mui/icons-material/AddBox";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ReportIcon from "@mui/icons-material/Report";
import PollIcon from "@mui/icons-material/Poll";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { ReactComponent as Logo } from "assets/logo.svg";

import { getAuth } from "store/authSlice";
import { SupportAgent } from "@mui/icons-material";

const routes = {
  admin: [
    {
      route: "admin/setting",
      label: "Cài đặt",
      icon: ({ color }) => <SettingsIcon sx={{ color: color }} />,
      permission: ["admin"],
      submenu: [
        {
          route: "admin/setting/prescription",
          label: "Đơn thuốc",
          icon: ({ color }) => <MedicationIcon sx={{ color: color }} />,
          permission: ["admin"],
        },
        {
          route: "admin/setting/procedure",
          label: "Thủ thuật",
          icon: ({ color }) => <TipsAndUpdatesIcon sx={{ color: color }} />,
          permission: ["admin"],
        },
        // Add more submenus here
      ],
    },
    {
      route: "admin/user/account",
      label: "Tài khoản",
      icon: ({ color }) => <ManageAccountsIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/user/patient",
      label: "Bệnh nhân",
      icon: ({ color }) => <BadgeIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/material",
      label: "Thuốc và Vật tư",
      icon: ({ color }) => <VaccinesIcon sx={{ color: color }} />,
      permission: ["admin"],
      submenu: [
        {
          route: "admin/material/product",
          label: "Thuốc, Vật tư",
          icon: ({ color }) => <AddBoxIcon sx={{ color: color }} />,
          permission: ["admin"],
        },
        {
          route: "admin/material/count",
          label: "Quản lý kho",
          icon: ({ color }) => <WarehouseIcon sx={{ color: color }} />,
          permission: ["admin"],
        },
        {
          route: "admin/material/price-list",
          label: "Quản lý giá",
          icon: ({ color }) => <LocalOfferIcon sx={{ color: color }} />,
          permission: ["admin"],
        },
      ],
    },
    {
      route: "admin/tips",
      label: "Thủ thuật",
      icon: ({ color }) => <TipsAndUpdatesIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/prescriptions",
      label: "Đơn khám",
      icon: ({ color }) => <CoPresentIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/report",
      label: "Báo cáo",
      icon: ({ color }) => <ReportIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/survey",
      label: "Khảo sát",
      icon: ({ color }) => <PollIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/consulation",
      label: "Tư vấn",
      icon: ({ color }) => <SupportAgent sx={{ color: color }} />,
      permission: ["admin"],
    },
    {
      route: "admin/post",
      label: "Bài đăng",
      icon: ({ color }) => <NewspaperIcon sx={{ color: color }} />,
      permission: ["admin"],
    },
  ],
  receptionist: [
    {
      route: "receptionist/patient",
      label: "Bệnh nhân",
      icon: ({ color }) => <BadgeIcon sx={{ color: color }} />,
      permission: ["receptionist"],
    },
    {
      route: "receptionist/health-records",
      label: "Đơn khám",
      icon: ({ color }) => <CoPresentIcon sx={{ color: color }} />,
      permission: ["receptionist"],
    },
    {
      route: "receptionist/report",
      label: "Báo cáo",
      icon: ({ color }) => <ReportIcon sx={{ color: color }} />,
      permission: ["receptionist"],
    },
    {
      route: "receptionist/survey",
      label: "Khảo sát",
      icon: ({ color }) => <PollIcon sx={{ color: color }} />,
      permission: ["receptionist"],
    },
    {
      route: "receptionist/consulation",
      label: "Tư vấn",
      icon: ({ color }) => <SupportAgent sx={{ color: color }} />,
      permission: ["receptionist"],
    },
  ],
  doctor: [
    {
      route: "doctor/health-records",
      label: "Sổ khám bệnh",
      icon: ({ color }) => <MenuBookIcon sx={{ color: color }} />,
      permission: ["doctor"],
    },
    {
      route: "doctor/setting",
      label: "Cài đặt",
      icon: ({ color }) => <SettingsIcon sx={{ color: color }} />,
      permission: ["doctor"],
      submenu: [
        {
          route: "doctor/setting/prescription",
          label: "Đơn thuốc",
          icon: ({ color }) => <MedicationIcon sx={{ color: color }} />,
          permission: ["doctor"],
        },
        {
          route: "doctor/setting/procedure",
          label: "Thủ thuật",
          icon: ({ color }) => <TipsAndUpdatesIcon sx={{ color: color }} />,
          permission: ["doctor"],
        },
        // Add more submenus here
      ],
    },
  ],
};

export default function AppSidebar() {
  const { isLogin, role } = useSelector(getAuth);
  const { pathname } = useLocation();
  const [openSubmenu, setOpenSubmenu] = React.useState(null);

  const handleSubmenuClick = (label) => {
    setOpenSubmenu((prevLabel) =>
      prevLabel === label ? pathname.slice(1, pathname.length) : label
    );
  };

  const renderRoute = (route) => {
    const isActive = route.route === pathname.slice(1, pathname.length);
    if (route.submenu) {
      const isOpen = openSubmenu === route.label;

      return (
        <React.Fragment key={route.label}>
          <ListItem
            onClick={() => handleSubmenuClick(route.label)}
            disablePadding
            className="border-b border-[#f9f5f5]"
            style={{ background: isOpen || isActive ? "#d6d6d6" : "" }}
          >
            <ListItemButton>
              <ListItemIcon>{route.icon({})}</ListItemIcon>
              <ListItemText
                primary={route.label}
                className="whitespace-nowrap"
              />
              <ListItemIcon className="flex justify-end -pr-4">
                {isOpen ? (
                  <ExpandMoreIcon fontSize="medium" />
                ) : (
                  <ExpandLessIcon fontSize="medium" />
                )}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {isOpen && (
            <List className="!p-0">
              {route.submenu.map((subRoute) => (
                <ListItem
                  key={subRoute.route}
                  disablePadding
                  className="border-b border-[#f9f5f5]"
                  style={{
                    background:
                      subRoute.route === pathname.slice(1, pathname.length)
                        ? "#d6d6d6"
                        : "",
                  }}
                >
                  <ListItemButton component={Link} to={`/${subRoute.route}`}>
                    <ListItemIcon style={{ marginLeft: "10px" }}>
                      {subRoute.icon({})}
                    </ListItemIcon>
                    <ListItemText
                      primary={subRoute.label}
                      className="whitespace-nowrap"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <ListItem
          key={route.route}
          disablePadding
          className="border-b border-[#f9f5f5]"
          style={{ background: isActive ? "#d6d6d6" : "" }}
        >
          <ListItemButton component={Link} to={`/${route.route}`}>
            <ListItemIcon>{route.icon({})}</ListItemIcon>
            <ListItemText primary={route.label} />
          </ListItemButton>
        </ListItem>
      );
    }
  };

  return (
    <>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <div variant="h4" className="w-full !font-bold text-center flex justify-center items-center">
            <span className="w-[80px] p-3">
              <Logo />
            </span>
          </div>
        </Toolbar>
        <Divider />
        {isLogin &&
          routes[role].map((route) => (
            <React.Fragment key={route.label}>
              {renderRoute(route)}
            </React.Fragment>
          ))}
      </Drawer>
    </>
  );
}
