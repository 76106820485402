import { FormHelperText } from "@mui/material";
import uploadService from "api/upload";

const UploadPDF = ({
  value,
  onChange,
  folder,
  disabled = false,
  error,
  helperText,
  classes,
}) => {
  const handleImageChange = async (e) => {
    const file = e.target.files?.length && e.target.files[0];
    if (file && /^application\/pdf/.test(file.type)) {
      const formData = new FormData();
      formData.append("folder", folder);
      formData.append("file", file);
    
      const { data } = await uploadService.uploadFile(formData);
      if (data?.location) {
        onChange(data?.location);
      }
    }
  };

  return (
    <>
      <div>
        <input
          disabled={disabled}
          type="file"
          name="filePDF"
          accept="pdf/*"
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: "pointer",
          }}
        />
      </div>
      <FormHelperText error={error} className={classes}>
        {value ? (
          <div className="w-full flex gap-4 whitespace-nowrap overflow-hidden">{Array.isArray(value) ? value[value.length - 1] : value}</div>
        ) : (
          helperText
        )}
      </FormHelperText>
    </>
  );
};

export default UploadPDF;
