import { CloseOutlined } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";
import uploadService from "api/upload";

const UploadImage = ({
  value,
  onChange,
  folder,
  imageAspect = "3/2",
  disabled = false,
  error,
  helperText,
  classes,
  form,
  setForm
}) => {
  const handleImageChange = async (e) => {
    const file = e.target.files?.length && e.target.files[0];
    if (file && /^image\//.test(file.type)) {
      const formData = new FormData();
      formData.append("folder", folder);
      formData.append("file", file);

      const { data } = await uploadService.uploadFile(formData);
      if (data?.location) {
        onChange(data?.location);
      }
    }
  };

  const handleDelete = (img) => {
    setForm({ ...form, images: form?.images?.filter((item) => item !== img) });
  };

  return (
    <>
      <div>
        {value && (
          <div className="flex gap-4">
            {value?.map((item) => (
              <div key={item} className="relative">
                <img
                  src={item}
                  style={{
                    marginBottom: 20,
                    aspectRatio: imageAspect,
                    objectFit: "cover",
                    maxWidth: 250
                  }}
                  className="border p-2"
                  alt=""
                />
                <button
                  className="absolute -top-2 -right-2 border rounded-full bg-red-200 cursor-pointer z-50"
                  onClick={() => handleDelete(item)}
                >
                  <CloseOutlined />
                </button>
              </div>
            ))}
          </div>
        )}
        <input
          disabled={disabled}
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: "pointer",
          }}
        />
      </div>
      <FormHelperText error={error} className={classes}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default UploadImage;
