/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { Divider, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import SurveyService from "api/surveys";

const SurveyRecep = () => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState({});

  const columns = [
    { field: "id", headerName: "#ID", width: 20 },
    { field: "name", headerName: "Tên", width: 200 },
    { field: "phone", headerName: "Số điện thoại", width: 150 },
    {
      field: "age",
      headerName: "Ngày sinh",
      width: 120,
      renderCell: (params) => {
        return <span>{moment(params.row.age).format("DD-MM-YYYY")}</span>;
      },
    },
    { field: "option1", headerName: "Lựa chọn 1", width: 250 },
    {
      field: "option2",
      headerName: "Lựa chọn 2",
      width: 650,
      renderCell: (params) => {
        return (
          <div className="flex gap-2">
            {params?.row?.option2?.length &&
              params?.row?.option2?.map((item) => <div>{item}</div>)}
          </div>
        );
      },
    },
    {
      field: "imgaes",
      headerName: "Hình ảnh",
      width: 350,
      renderCell: (params) => {
        return (
          <span className="p-3">
            {params?.row?.images?.map((item) => (
              <img src={item} alt="" />
            ))}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 120,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const fetchSurvey = async () => {
    try {
      const response = await SurveyService.getSurveyRecep({
        name: search.name ? search.name : null,
        phone: search.phone ? search.phone : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSurvey();
  }, [search?.name, search?.phone]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý khảo sát
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          value={search?.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          label="Tên"
          placeholder="Tên"
          className="w-[300px]"
        />
        <TextField
          value={search?.phone}
          onChange={(e) => setSearch({ ...search, phone: e.target.value })}
          label="Số điện thoại"
          placeholder="Số điện thoại"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", width: "83vw" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default SurveyRecep;
