import axios from "../api";

export const getPricelists = async (params) =>
  axios.get("/admin/price-list", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const createPricelists = async (payload) =>
  axios.post("/admin/price-list", payload);

const PricelistServices = {
  getPricelists,
  createPricelists,
};

export default PricelistServices;
