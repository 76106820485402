import React from "react";

const Setting = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};

export default Setting;
