/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { Divider, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import ConsulationService from "api/consulations";

const Consulation = () => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState({});

  const columns = [
    { field: "id", headerName: "#ID", width: 20 },
    { field: "name", headerName: "Tên", width: 250 },
    { field: "phone", headerName: "Số điện thoại", width: 250 },
    {
      field: "processed",
      headerName: "Trạng thái",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            color: !params.row.processed ? "gray" : "green",
          }}
        >
          <p className="rounded-md">
            {!params.row.processed
              ? "Chưa tư vấn"
              : "Đã tư vấn"}
          </p>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 120,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const fetchConsulation = async () => {
    try {
      const response = await ConsulationService.getConsulation({
        name: search.name ? search.name : null,
        phone: search.phone ? search.phone : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchConsulation();
  }, [search?.name, search?.phone]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý khảo sát
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          value={search?.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          label="Tên"
          placeholder="Tên"
          className="w-[300px]"
        />
        <TextField
          value={search?.phone}
          onChange={(e) => setSearch({ ...search, phone: e.target.value })}
          label="Số điện thoại"
          placeholder="Số điện thoại"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", width: "83vw" }}>
        <DataGrid
          rowHeight={120}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Consulation;
