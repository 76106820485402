import axios from "../api";

export const uploadFile = (formData) =>
  axios.post("/uploads", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const uploadService = {
  uploadFile,
};

export default uploadService;
