import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { UNIT, types } from "constant";
import { formatCurrencyVND } from "utils/helper";

const Update = ({ form, handleSubmitFormUpdate, handleChangeInput }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Tên"
            variant="outlined"
            name="name"
            className="w-full"
            value={form?.name}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="type"
            className="w-full"
            disablePortal
            options={types}
            value={form?.type}
            inputValue={form?.type}
            onInputChange={(_, e) =>
              handleChangeInput({
                target: { name: "type", value: e.toLocaleLowerCase() },
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Loại" name="type" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            name="unit"
            className="w-full"
            disablePortal
            options={UNIT}
            value={form?.unit}
            inputValue={form?.unit}
            onInputChange={(_, e) =>
              handleChangeInput({
                target: { name: "unit", value: e.toLocaleLowerCase() },
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Đơn vị" name="unit" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Giá bán"
            variant="outlined"
            name="sellingPricePerUnit"
            className="w-full"
            defaultValue={form?.currentSellingPricePerUnit}
            value={form?.sellingPricePerUnit}
            onChange={handleChangeInput}
            helperText={
              (form?.sellingPricePerUnit &&
                formatCurrencyVND(form?.sellingPricePerUnit)) ||
              formatCurrencyVND(form?.currentSellingPricePerUnit)
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextareaAutosize
            placeholder="Ghi chú"
            name="description"
            className="border border-gray-300 w-full !h-[200px] p-3 rounded-md"
            value={form?.description}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitFormUpdate}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Update;
