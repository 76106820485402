import axios from "../api";

export const getConsulation = (params) =>
  axios.get("/admin/consulations", {
    params: {
      ...params,
      orderBy: "desc",
      perPage: 100,
    },
  });

export const getConsulationRecep = (params) =>
  axios.get("/receptionist/consulations", {
    params: {
      ...params,
      orderBy: "desc",
      perPage: 100,
    },
  });

export const checkConsulation = (id) =>
  axios.patch(`/receptionist/consulations/${id}`, {
    processed: true,
  });

const ConsulationService = {
  getConsulation,
  getConsulationRecep,
  checkConsulation,
};

export default ConsulationService;
