/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { formatCurrencyVND } from "utils/helper";

const FinishPrescription = ({ form, handleChangeInput, handleSubmitForm }) => {
  return (
    <>
      <Box p={4} className="flex flex-col gap-4">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">Ngày tái khám</Typography>
            <TextField
              type="date"
              className="w-full border rounded-sm p-4"
              name="dateReeXam"
              value={form?.dateReeXam}
              onChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Thời gian cụ thể</Typography>
            <TextField
              className="w-full border rounded-sm p-4"
              name="session"
              placeholder={"VD: ... giờ ... phút, buổi ..."}
              value={form?.session}
              onChange={handleChangeInput}
              helperText={"VD: 9 giờ 30 phút, buổi sáng"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Tổng Phí</Typography>
            <TextField
              className="w-full border rounded-sm p-4"
              onChange={handleChangeInput}
              name="totalFee"
              value={form?.totalFee}
              helperText={form?.totalFee && formatCurrencyVND(form?.totalFee)}
              type="number"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Phí cuối cùng</Typography>
            <TextField
              className="w-full border rounded-sm p-4"
              onChange={handleChangeInput}
              name="finalFee"
              value={form?.finalFee}
              helperText={form?.finalFee && formatCurrencyVND(form?.finalFee)}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Lý do</Typography>
            <textarea
              className="w-full border rounded-sm p-4 h-[150px]"
              onChange={handleChangeInput}
              name="reason"
              value={form?.reason}
            />
          </Grid>
        </Grid>
      </Box>
      <Box container px={4} my={2}>
        <Button onClick={handleSubmitForm} className="!bg-blue-200 !text-black">
          Hoàn thành
        </Button>
      </Box>
    </>
  );
};

export default FinishPrescription;
