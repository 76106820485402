import axios from ".";

export const getUser = async (params) =>
  axios.get("/receptionist/users", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });
export const getUserById = async (id) => axios.get(`/receptionist/users/${id}`);

export const createUser = async (payload) =>
  axios.post("/receptionist/users", payload);

export const updateUser = async (id, payload) =>
  axios.patch(`/receptionist/users/${id}`, payload);

const ReceptionistServices = {
  getUser,
  createUser,
  updateUser,
  getUserById,
};

export default ReceptionistServices;
