import axios from "../api";

export const getMaterials = async (params) =>
  axios.get("/admin/medical-materials", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const createMaterials = async (payload) =>
  axios.post("/admin/medical-materials", payload);

export const updateMaterials = async (id, payload) =>
  axios.patch(`/admin/medical-materials/${id}`, payload);

export const getMaterialsDoctor = async (params) =>
  axios.get("/doctor/medical-materials", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const getMaterialsDoctorId = async (id) =>
  axios.get(`/doctor/medical-materials/${id}`);

const MaterialServices = {
  getMaterials,
  createMaterials,
  updateMaterials,
  getMaterialsDoctor,
  getMaterialsDoctorId,
};

export default MaterialServices;
