import axios from "../api";

export const getDataWithKey = async (key) =>
  axios.get(`/admin/settings/${key}`);

export const createDataWithKey = async (payload) =>
  axios.post("/admin/settings", payload);

export const updateDataWithKey = async (key, payload) =>
  axios.patch(`/admin/settings/${key}`, payload);

export const getDataWithKeyDoctor = async (key) =>
  axios.get(`/doctor/settings/${key}`);

export const createDataWithKeyDoctor = async (payload) =>
  axios.post("/doctor/settings", payload);

export const updateDataWithKeyDoctor = async (key, payload) =>
  axios.patch(`/doctor/settings/${key}`, payload);

export const getKeyValue = async (key) => axios.get(`/settings/${key}`);

export const deleteKey = async (key) => axios.delete(`/admin/settings/${key}`);

export const deleteKeyDoctor = async (key) => axios.delete(`/doctor/settings/${key}`);

const SettingServices = {
  getDataWithKey,
  createDataWithKey,
  updateDataWithKey,
  getDataWithKeyDoctor,
  createDataWithKeyDoctor,
  updateDataWithKeyDoctor,
  getKeyValue,
  deleteKey,
  deleteKeyDoctor
};

export default SettingServices;
