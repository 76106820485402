import axios from "../api";

export const getProcedures = async (params) =>
  axios.get("/admin/medical-procedures", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const createProcedures = async (payload) =>
  axios.post("/admin/medical-procedures", payload);

export const updateProcedures = async (id, payload) =>
  axios.patch(`/admin/medical-procedures/${id}`, payload);

export const getProceduresDoctor = async (params) =>
  axios.get("/doctor/medical-procedures", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

const ProcedureServices = {
  getProcedures,
  createProcedures,
  updateProcedures,
  getProceduresDoctor,
};

export default ProcedureServices;
