import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthServices from "api/auth";
import { USER_INFO } from "constant";
import { loginReducer } from "store/authSlice";
import { setUserStorage } from "utils/storage";
import { ReactComponent as Logo} from "assets/logo.svg";

import { Box, Button, Container, TextField, Typography } from "@mui/material";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
      username: data.get("name"),
      password: data.get("password"),
    };
    try {
      const response = await AuthServices.login(payload);
      const { accessToken, refreshToken, user } = response?.data;
      if (user) {
        dispatch(loginReducer({ isLogin: true, user: user, role: user.role }));
        const token = { accessToken, refreshToken };
        setUserStorage(USER_INFO, { user, token });

        const timeout = setTimeout(() => {
          navigate(`/${user.role}`);
        }, 1000);

        return () => clearTimeout(timeout);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-screen w-full flex flex-col -mt-8 justify-center items-center">
      <div className="text-[130px] font-bold h-[150px] w-[150px] mb-4">
        <Logo />
      </div>
      <Typography
        variant="h5"
        fontWeight="bold"
        className="max-w-[480px] text-center"
      >
        PHÒNG KHÁM
      </Typography>
      <Typography
        variant="h5"
        fontWeight="bold"
        className="max-w-[480px] text-center"
      >
        LỒNG NGỰC MẠCH MÁU
      </Typography>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 4 ,
            px: 4,
            py: 6,
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "white",
          }}
        >
          <Typography component="h1" variant="h5">
            Đăng nhập
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Tài khoản"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Đăng nhập
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
