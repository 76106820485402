import { Print, PrintOutlined, Visibility } from "@mui/icons-material";
import FilterIcon from "@mui/icons-material/Filter";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";

import PrescriptionServices from "api/prescriptions";
import { STATUS } from "constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { open } from "store/notificationSlice";
import { formatCurrencyVND } from "utils/helper";
import Create from "./create";
import Detail from "./deital";
import FinishPrescription from "./finishPrescription";
import ImagesModal from "./modalImage";
import PrintPrescription from "./printPresciption";
import PrintReceipt from "./printReceipt";
import PrintReceipt2 from "./printReceipt2";

const HealthRecords = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDrawerDetail, setShowDrawerDetail] = useState(false);
  const [showDrawerPrintReceipt, setShowDrawerPrintReceipt] = useState(false);
  const [showDrawerPrintReceipt2, setShowDrawerPrintReceipt2] = useState(false);
  const [showDrawerPrintPrescription, setShowDrawerPrintPrescription] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listImg, setListImg] = useState([]);
  const [form, setForm] = useState({
    consultationFee: 50000,
  });
  const [rows, setRows] = useState([]);
  const [detail, setDetail] = useState();
  const [search, setSearch] = useState("");
  const printDetailRef = useRef(null);
  const printPrescriptionRef = useRef(null);
  const printReceiptRef = useRef(null);
  const printReceipt2Ref = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [drawerSubmit, setDrawerSubmit] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "#ID",
      width: 40,
    },
    {
      field: "code",
      headerName: "Mã đơn khám",
      width: 180,
    },
    {
      field: "name",
      headerName: "Bệnh nhân",
      width: 210,
      renderCell: (params) => {
        return <p>{params.row.patient.name}</p>;
      },
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      width: 140,
      renderCell: (params) => {
        return <p>{params.row.patient.phone}</p>;
      },
    },
    {
      field: "images",
      headerName: "Ảnh",
      width: 100,
      renderCell: (params) => {
        return (
          <button
            className="p-1 rounded-md text-blue-400 hover:bg-gray-200"
            onClick={() => handleOpenModalImg(params.row.images)}
          >
            <FilterIcon />
          </button>
        );
      },
    },
    {
      field: "consultationFee",
      headerName: "Phí sơ khám",
      width: 150,
      renderCell: (params) => {
        return <p>{formatCurrencyVND(params.row.consultationFee)}</p>;
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày khám",
      width: 150,
      renderCell: (params) => {
        return <p>{moment(params.row.createdAt).format("DD/MM/YYYY")}</p>;
      },
    },
    {
      field: "status",
      headerName: "Thanh toán",
      width: 170,
      renderCell: (params) => (
        <div
          className="w-full text-center"
          style={{
            color: STATUS[params.row.status].color,
          }}
        >
          <p className="w-32 p-1 rounded-md bg-[#dedede]">
            {STATUS[params.row.status].label}
          </p>
        </div>
      ),
    },
    {
      field: "totalFee",
      headerName: "Tổng phí",
      width: 120,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.totalFee)}</p>,
    },
    {
      field: "finalFee",
      headerName: "Phí đã sửa",
      width: 120,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.finalFee)}</p>,
    },
    {
      field: "detail",
      headerName: "Chi tiết",
      width: 120,
      renderCell: (params) => (
        <div className="flex -ml-2 gap-1">
          <Button onClick={() => handleShowDetail(params.row)}>
            <Visibility />
          </Button>
        </div>
      ),
    },
    {
      field: "print",
      headerName: "In biên nhận chi tiết",
      width: 180,
      renderCell: (params) => (
        <div className="flex ml-8 gap-1">
          <Button onClick={() => handleShowReceipt(params.row.code)}>
            <PrintOutlined />
          </Button>
        </div>
      ),
    },
    {
      field: "print2",
      headerName: "In biên nhận",
      width: 150,
      renderCell: (params) => (
        <div className="flex ml-2 gap-1">
          <Button onClick={() => handleShowReceipt2(params.row.code)}>
            <PrintOutlined />
          </Button>
        </div>
      ),
    },
    {
      field: "print3",
      headerName: "In toa thuốc",
      width: 150,
      renderCell: (params) => (
        <div className="flex ml-2 gap-1">
          <Button onClick={() => handleShowPrescription(params.row.code)}>
            <PrintOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const handleContinue = () => {
    setDrawerSubmit(true);
    previewTotalFee();
  };

  const handleOpenModalImg = (images) => {
    setListImg(images);
    setOpenModal(true);
  };

  const handleShowDetail = (data) => {
    setDetail(data);
    setShowDrawerDetail(true);
  };

  const handleShowPrescription = (data) => {
    fetchPrescriptionSimple(data);
    setShowDrawerPrintPrescription(true);
  };

  const handleShowReceipt = (data) => {
    fetchPrescriptionReceip1(data);
    setShowDrawerPrintReceipt(true);
  };

  const handleShowReceipt2 = (data) => {
    fetchPrescriptionReceip2(data);
    setShowDrawerPrintReceipt2(true);
  };

  const handleShowDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmitForm = async () => {
    const payload = {
      patientId: Number(form.patientId),
      consultationFee: Number(form.consultationFee) || 50000,
      description: form.description,
      medicalMaterials:
        form.prescriptions?.map((item) => ({
          description: item.description || "",
          medicalMaterialId: item.medicalMaterialId,
          quantityPerUnit: Number(item?.quantityPerUnit),
          timesAday: Number(item?.timesAday),
          timesADrink: Number(item?.timesADrink),
          time: item?.time,
        })) || [],
      medicalProcedures:
        form.procedures?.map((item) => ({
          description: item.description || "",
          medicalProcedureId: item.medicalProcedureId,
          quantityPerUnit: 1,
        })) || [],
      images: form.images || [],
      reason: form.reason || "",
      finalFee: Number(form.finalFee),
      reexamination: `${form.session ? form.session + ", " : ""} ${
        form.dateReeXam
          ? "ngày " + moment(form.dateReeXam).format("DD/MM/YYYY")
          : ""
      }`,
    };
    try {
      await PrescriptionServices.createPrescriptions(payload);
      setShowDrawer(false);
      setDrawerSubmit(false);
      fetchPrescriptions();
      setForm({});
      dispatch(open({ isNoti: true, message: "Tạo đơn khám thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  // const printPDF = async (ref) => {
  //   const element = ref.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("", "px", [
  //     ref.current.offsetWidth,
  //     ref.current.offsetHeight,
  //   ]);

  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("label.pdf");
  // };

  const printPDF1 = useReactToPrint({
    content: () => printDetailRef.current,
    documentTitle: "data",
  });
  const printPDF2 = useReactToPrint({
    content: () => printPrescriptionRef.current,
    documentTitle: "data",
  });
  const printPDF3 = useReactToPrint({
    content: () => printReceiptRef.current,
    documentTitle: "data",
  });
  const printPDF4 = useReactToPrint({
    content: () => printReceipt2Ref.current,
    documentTitle: "data",
  });

  const fetchPrescriptions = async () => {
    try {
      const response = await PrescriptionServices.getPrescriptions({
        perPage: 100,
        doctorName: user?.name,
        search: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrescriptionSimple = async (code) => {
    try {
      const response = await PrescriptionServices.getPrescriptionSimple(code);
      setDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrescriptionReceip1 = async (code) => {
    try {
      const response = await PrescriptionServices.getPrescriptionReceip1(code);
      setDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrescriptionReceip2 = async (code) => {
    try {
      const response = await PrescriptionServices.getPrescriptionReceip2(code);
      setDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrescriptions();
  }, [search, user?.name]);

  const previewTotalFee = async () => {
    const payload = {
      consultationFee: Number(form.consultationFee) || 50000,
      medicalMaterials:
        form.prescriptions?.map((item) => ({
          medicalMaterialId: item.medicalMaterialId,
          quantityPerUnit: Number(item?.quantityPerUnit),
        })) || [],
      medicalProcedures:
        form.procedures?.map((item) => ({
          medicalProcedureId: item.medicalProcedureId,
          quantityPerUnit: 1,
        })) || [],
    };
    try {
      const res = await PrescriptionServices.previewTotal(payload);
      setForm({
        ...form,
        totalFee: res.data.totalFee,
        finalFee: res.data.totalFee,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý đơn khám
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Tạo ca khám
        </Button>
        <TextField
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm lịch sử khám"
          placeholder="Tên, số điện thoại, mã bệnh nhân"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", maxWidth: "83vw", overflow: "scroll" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showDrawerDetail}
        onClose={() => setShowDrawerDetail(false)}
        PaperProps={{
          sx: {
            width: 1170,
          },
        }}
      >
        <Grid className="flex justify-between border border-b">
          <Typography variant="h5" className="p-3">
            Thông tin đơn khám
          </Typography>
          <Typography
            className="flex justify-center gap-2 p-5 bg-gray-200 cursor-pointer"
            onClick={printPDF1}
          >
            <Print />
            <span className="!text-[16px]">Export PDF</span>
          </Typography>
        </Grid>

        <div ref={printDetailRef}>
          <Detail
            data={detail}
            list={rows}
            setList={setRows}
            form={form}
            setForm={setForm}
            handleChangeInput={handleChangeInput}
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={showDrawerPrintReceipt}
        onClose={() => setShowDrawerPrintReceipt(false)}
        PaperProps={{
          sx: {
            width: 870,
          },
        }}
      >
        <Grid className="flex justify-between border border-b">
          <Typography
            className="flex justify-center gap-2 p-5 bg-gray-200 cursor-pointer"
            onClick={printPDF3}
          >
            <Print />
            <span className="!text-[16px]">Export PDF</span>
          </Typography>
        </Grid>

        <div ref={printReceiptRef}>
          <PrintReceipt
            data={detail}
            list={rows}
            setList={setRows}
            form={form}
            setForm={setForm}
            handleChangeInput={handleChangeInput}
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={showDrawerPrintReceipt2}
        onClose={() => setShowDrawerPrintReceipt2(false)}
        PaperProps={{
          sx: {
            width: 870,
          },
        }}
      >
        <Grid className="flex justify-between border border-b">
          <Typography
            className="flex justify-center gap-2 p-5 bg-gray-200 cursor-pointer"
            onClick={printPDF4}
          >
            <Print />
            <span className="!text-[16px]">Export PDF</span>
          </Typography>
        </Grid>

        <div ref={printReceipt2Ref}>
          <PrintReceipt2
            data={detail}
            list={rows}
            setList={setRows}
            form={form}
            setForm={setForm}
            handleChangeInput={handleChangeInput}
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={showDrawerPrintPrescription}
        onClose={() => setShowDrawerPrintPrescription(false)}
        PaperProps={{
          sx: {
            width: 1270,
          },
        }}
      >
        <Grid className="flex justify-end border border-b">
          <Typography
            className="flex justify-center gap-2 p-3 bg-gray-200 cursor-pointer"
            onClick={printPDF2}
          >
            <Print />
            <span className="!text-[14px]">Export PDF</span>
          </Typography>
        </Grid>
        <div ref={printPrescriptionRef}>
          <PrintPrescription
            data={detail}
            list={rows}
            setList={setRows}
            form={form}
            setForm={setForm}
            handleChangeInput={handleChangeInput}
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: {
            width: 1070,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin ca khám
        </Typography>
        <Create
          list={rows}
          setList={setRows}
          form={form}
          setForm={setForm}
          handleChangeInput={handleChangeInput}
          handleSubmitForm={handleSubmitForm}
          handleContinue={handleContinue}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={drawerSubmit}
        onClose={() => setDrawerSubmit(false)}
        PaperProps={{
          sx: {
            width: 1070,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Xác nhận hoàn thành đơn khám
        </Typography>
        <FinishPrescription
          list={rows}
          setList={setRows}
          form={form}
          setForm={setForm}
          handleChangeInput={handleChangeInput}
          handleSubmitForm={handleSubmitForm}
        />
      </Drawer>

      <ImagesModal list={listImg} open={openModal} setOpen={setOpenModal} />
    </Box>
  );
};

export default HealthRecords;
