/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Typography } from "@mui/material";
import TableDetail from "./tableDetail";
import moment from "moment";

const Detail = ({ data }) => {
  return (
    <Container>
      <Box p={6}>
        <Grid container spacing={3}>
          <Grid
            xs={4}
            className="flex justify-start items-start font-bold -mt-2"
          >
            <p className="text-center !max-w-[220px] text-[19px]">
              Phòng khám lồng ngực mạch máu
            </p>
          </Grid>
          <Grid
            xs={4}
            className="flex !flex-col justify-center items-center mt-6"
          >
            <p className="text-2xl font-bold">ĐƠN KHÁM</p>
            <p>
              Ngày khám: {moment(data?.createdAt).format("DD/MM/YYYY")}, lúc{" "}
              {moment(data?.createdAt).format("HH:MM")}
            </p>
          </Grid>
          <Grid xs={4} className="w-full flex justify-end items-start -mt-2">
            <div className="flex flex-col">
              <p className="flex gap-2 text-[19px]">
                <span>Mã đơn:</span>
                <span>{data?.code}</span>
              </p>
              <p className="flex gap-2 text-[19px]">
                <span>STT:</span>
                <span>{data?.id}</span>
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={4}>
          <Grid xs={6}>
            <p className="flex gap-4 text-[18px] my-1">
              <span className="font-bold whitespace-nowrap">Bệnh nhân: </span>
              <span>{data?.patient?.name}</span>
            </p>
            <p className="flex gap-4 text-[18px] my-1">
              <span className="font-bold whitespace-nowrap">Địa chỉ: </span>
              <span>{data?.patient?.address}</span>
            </p>
            <p className="flex gap-4 text-[18px] my-1">
              <span className="font-bold whitespace-nowrap">Ngày sinh: </span>
              <span>{moment(data?.patient?.dob).format("DD/MM/YYYY")}</span>
            </p>
          </Grid>
          <Grid xs={6}>
            <p className="flex gap-4 text-[18px] my-1">
              <span className="font-bold whitespace-nowrap">Giới tính:</span>
              <span>{data?.patient?.sex === "Male" ? "Nam" : "Nữ"}</span>
            </p>
            <p className="flex gap-4 text-[18px] my-1">
              <span className="font-bold whitespace-nowrap">
                Số điện thoại:
              </span>
              <span>{data?.patient?.phone}</span>
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={4} my={4}>
          <p className="flex gap-4 text-[18px] my-1">
            <span className="font-bold whitespace-nowrap">Chẩn đoán:</span>
            <span>{data?.description}</span>
          </p>
        </Grid>
        <Grid container spacing={4} mt={4}>
          <Typography fontWeight={700} className="!text-[19px]">
            Ảnh triệu chứng
          </Typography>
          <Grid item xs={12}>
            <div className="w-full flex gap-4 justify-center">
              {data?.images?.map((item) => (
                <img key={item} src={item} alt="" className="min-h-[500px]" />
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={8}>
          <Typography fontWeight={700} className="!text-[19px]">
            Toa thuốc và Thủ thuật
          </Typography>
          <Grid xs={12} mt={3}>
            <TableDetail data={data} />
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={8}>
          <Grid xs={7}></Grid>
          <Grid xs={5} className="text-center">
            <Typography className="!font-bold !text-xl">Bác sĩ khám</Typography>
            <Typography className="!text-lg !mt-12">
              {data?.doctor?.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Detail;
