/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Autocomplete,
  TableHead,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { INSTRUCTIONS, TIMES } from "constant";
import MaterialServices from "api/medical-materials";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: "none",
    width: "100%",
  },
  table: {
    width: "100%",
    background: "#ebebeb",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    width: "25%",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
  name: {
    color: "#146001f9",
  },
}));

const tableHeader = [
  { label: "Tên thuốc", data: "medicalMaterialName" },
  { label: "Số lượng", data: "quantityPerUnit" },
  { label: "Liều lượng", data: "description" },
  { label: "Ghi chú", data: "description" },
  { label: "", data: "" },
];

const TablePrescription = ({ form, setForm, show = false, children }) => {
  const [newData, setNewData] = useState({});
  const [medicals, setMedicals] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "medicalMaterialName") {
      setNewData({
        ...newData,
        medicalMaterialId: value?.id,
        medicalMaterialName: value?.name,
      });
    } else {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const addItem = () => {
    if (
      (!newData.medicalMaterialName || !newData.quantityPerUnit,
      !newData.timesADrink,
      !newData.timesAday,
      !newData.time)
    ) {
      alert("Nhập đủ trường!");
      return;
    }
    let isSame = false;
    const newArray =
      form?.prescriptions?.map((item) => {
        if (item.medicalMaterialName === newData.medicalMaterialName) {
          isSame = true;
          return {
            ...newData,
            quantityPerUnit:
              Number(item.quantityPerUnit) + Number(newData.quantityPerUnit),
          };
        } else {
          return item;
        }
      }) || [];

    if (!isSame) {
      newArray.push(newData);
    }

    setForm({
      ...form,
      prescriptions: newArray || [],
    });
    setNewData(null);
    dispatch(open({ isNoti: true, message: "Đã thêm thuốc" }));
  };

  const deleteItem = (medicalMaterialName) => {
    const newData = form?.prescriptions?.filter(
      (data) => data.medicalMaterialName !== medicalMaterialName
    );
    setForm({ ...form, prescriptions: newData });
    setNewData();
  };

  const filterData = async (inputValue) => {
    try {
      const response = await MaterialServices.getMaterialsDoctor({
        type: "prescription",
        name: inputValue,
      });
      return response.data.data?.map((item) => ({
        label: item.name,
        value: item,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterData(inputValue));
    });

  const fetchMedical = async () => {
    try {
      const response = await MaterialServices.getMaterialsDoctor({
        type: "prescription",
        name: newData?.medicalMaterialName,
      });
      setMedicals(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMedical();
  }, [newData?.medicalMaterialName]);

  return (
    <>
      {!show ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            {!show && (
              <TableHead className="bg-slate-300">
                <TableRow>
                  {tableHeader.map((cell) => (
                    <TableCell
                      key={cell.data}
                      className={classes.tableHeaderCell}
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {children}
              {form?.prescriptions?.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell className="w-[25%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        {row.medicalMaterialName}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell className="w-[25%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        {row.quantityPerUnit} {row?.unit}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell className="w-[25%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        ngày uống {row.timesAday} lần, mỗi lần {row.timesADrink}{" "}
                        viên, {row.time} ăn
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell className="w-[25%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        {row.description}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item>
                        <Typography>
                          <DeleteIcon
                            color="secondary"
                            onClick={deleteItem.bind(
                              this,
                              row.medicalMaterialName
                            )}
                            className={classes.hover}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {form?.prescriptions?.map((row, i) => (
            <TableRow key={row.id}>
              <TableCell className="w-[32%]">
                <Grid container>
                  <Typography className={classes.name}>
                    {row.medicalMaterialName}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className="w-[32%]">
                <Grid container>
                  <Typography className={classes.name}>
                    {row.description}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell className="w-[32%]">
                <Grid container>
                  <Typography className={classes.name}>
                    {row.quantityPerUnit} {row?.unit}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(this, row.medicalMaterialName)}
                        className={classes.hover}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
      {!show && (
        <>
          <Box className="w-full" my={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={medicals?.map((item) => ({
                    label: item.name,
                    value: item,
                  }))}
                  loadOptions={promiseOptions}
                  placeholder="Tên thuốc"
                  value={
                    newData?.medicalMaterialName
                      ? { label: newData?.medicalMaterialName }
                      : null
                  }
                  onChange={(option) =>
                    handleFormChange({
                      target: {
                        name: "medicalMaterialName",
                        value: {
                          name: option.value?.name,
                          id: option.value?.id,
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Số lượng"
                  onChange={handleFormChange}
                  name="quantityPerUnit"
                  value={newData?.quantityPerUnit || ""}
                  className="w-full"
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Ghi chú"
                  onChange={handleFormChange}
                  name="description"
                  value={newData?.description || ""}
                  className="w-full"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Ngày uống mấy lần"
                  onChange={handleFormChange}
                  name="timesAday"
                  value={newData?.timesAday || ""}
                  className="w-full"
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Lần uống mấy viên"
                  onChange={handleFormChange}
                  name="timesADrink"
                  value={newData?.timesADrink || ""}
                  className="w-full"
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  className="w-full"
                  disablePortal
                  options={TIMES}
                  inputValue={newData?.time || ""}
                  value={newData?.time || ""}
                  onInputChange={(_, e) =>
                    handleFormChange({
                      target: {
                        name: "time",
                        value: e,
                      },
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Trước hay sau ăn"
                      name="time"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item lg={4}>
              <Typography>
                <Button
                  variant="contained"
                  onClick={addItem}
                  startIcon={<AddIcon />}
                >
                  Thêm
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TablePrescription;
