import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import Create from "./create";
import Update from "./update";
import MaterialServices from "api/medical-materials";
import { compareObjects, formatCurrencyVND } from "utils/helper";
import { types } from "constant";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const defaultValue = {
  description: "",
  unit: "",
  sellingPricePerUnit: 0,
};

const Material = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState(defaultValue);
  const [search, setSearch] = useState({});
  const dispatch = useDispatch();
  const [oldData, setOldData] = useState({});

  const columns = [
    { field: "id", headerName: "#ID", width: 30 },
    { field: "name", headerName: "Tên", width: 170 },
    {
      field: "type",
      headerName: "Loại",
      width: 110,
    },
    // {
    //   field: "description",
    //   headerName: "Ghi chú",
    //   width: 170,
    // },
    {
      field: "quantityPerUnit",
      headerName: "SL đơn vị tổng",
      width: 170,
    },
    {
      field: "remainingQuantityPerUnit",
      headerName: "SL đơn vị còn lại",
      width: 170,
    },
    {
      field: "unit",
      headerName: "Đơn vị",
      width: 160,
    },
    {
      field: "currentReceivedPricePerUnit",
      headerName: "Giá nhập",
      width: 130,
      renderCell: (params) => {
        return (
          <span>
            {formatCurrencyVND(params.row.currentReceivedPricePerUnit)}
          </span>
        );
      },
    },
    {
      field: "currentSellingPricePerUnit",
      headerName: "Giá bán",
      width: 130,
      renderCell: (params) => {
        return (
          <span>
            {formatCurrencyVND(params.row.currentSellingPricePerUnit)}
          </span>
        );
      },
    },
    {
      field: "edit",
      headerName: "Sửa",
      width: 40,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEdit(params.row)} className="w-full">
            <Edit />
          </Button>
        );
      },
    },
  ];

  const setDefaultForm = () => {
    setForm(defaultValue);
  };

  const handleShowDrawer = () => {
    setDefaultForm();
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleEdit = (data) => {
    setForm(data);
    setOldData(data);
    setShowDrawerUpdate(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const payload = {
        ...form,
      };
      await MaterialServices.createMaterials(payload);
      setShowDrawerCreate(false);
      setDefaultForm();
      fetchMaterials();
      dispatch(open({ isNoti: true, message: "Tạo thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitUpdate = async () => {
    try {
      const newData = compareObjects(oldData, form);
      const payload = {
        ...newData,
        sellingPricePerUnit:
          form?.sellingPricePerUnit && Number(form?.sellingPricePerUnit),
      };
      await MaterialServices.updateMaterials(form.id, payload);
      setShowDrawerUpdate(false);
      setDefaultForm();
      fetchMaterials();
      dispatch(open({ isNoti: true, message: "Cập nhật thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await MaterialServices.getMaterials({
        name: search?.name ? search?.name : null,
        type: search?.type ? search?.type : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, [search?.name, search.type]);

  console.log(form);
  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý Thuốc và Vật tư
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm thuốc và vật tư
        </Button>
        <TextField
          value={search?.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          label="Tìm kiếm thuốc và vật tư"
          placeholder="Tên"
          className="w-[300px]"
        />
        <Autocomplete
          name="type"
          className="w-[300px]"
          disablePortal
          options={[{ id: -1, label: "All", value: null }, ...types]}
          inputValue={search?.type}
          onInputChange={(_, e) =>
            setSearch({ ...search, type: e.toLocaleLowerCase() })
          }
          renderInput={(params) => (
            <TextField {...params} label="Loại" name="type" />
          )}
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin Thuốc và Vật tư
        </Typography>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chỉnh sửa thông tin Thuốc và Vật tư
        </Typography>
        <Update
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
        />
      </Drawer>
      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Material;
