/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrencyVND } from "utils/helper";
import DoctorServices from "api/doctor";
import moment from "moment";
import Select from "react-select";
import SettingServices from "api/setting";
import TableProcedure from "./tableProcedure";
import TablePrescription from "./tablePrescription";
import UploadImage from "components/UploadImage";

const Create = ({ form, setForm, handleChangeInput, handleContinue }) => {
  const [listUser, setListUser] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [prescriptions, setPrescription] = useState([]);
  const [procedures, setProcedure] = useState([]);
  const [templatePrescription, setTemplatePrescription] = useState([]);
  const [templateProcedure, setTemplateProcedure] = useState([]);
  const [search, setSearch] = useState("");

  const fetchListPrescription = async () => {
    const key = "prescriptions";
    try {
      const response = await SettingServices.getKeyValue(key);
      setPrescription(
        response.data.value.prescriptions?.map((item, index) => ({
          id: index,
          label: item.key,
          value: item,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListProcedure = async () => {
    const key = "procedures";
    try {
      const response = await SettingServices.getKeyValue(key);
      setProcedure(
        response.data.value.procedures?.map((item, index) => ({
          id: index,
          label: item.key,
          value: item,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchApiPatient = async () => {
      try {
        const response = await DoctorServices.getUsers({
          perPage: 100,
          search: search ? search : null,
        });
        setListUser(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchApiPatient();
  }, [form?.patientId, search]);

  useEffect(() => {
    fetchListPrescription();
    fetchListProcedure();
  }, []);

  useEffect(() => {
    if (!form?.precriptionKey?.length) {
      setTemplatePrescription([]);
    }
    const fetchPrescription = () => {
      try {
        const Promise1 =
          form?.precriptionKey?.map((item) => {
            return SettingServices.getKeyValue(item);
          }) || [];

        Promise.all([...Promise1]).then((response) => {
          const data = response.map((item) => item.data);
          setTemplatePrescription(data);
          setForm({
            ...form,
            prescriptionsTemplate: {
              value: data?.map((item) => item.value[item.key]),
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchPrescription();
  }, [form?.precriptionKey?.length]);

  useEffect(() => {
    let newA = [];
    form?.prescriptionsTemplate?.value?.map((item) => {
      newA = [...newA, ...item];
    });
    setForm({ ...form, prescriptions: newA });
  }, [form?.prescriptionsTemplate?.value?.length]);

  useEffect(() => {
    if (!form?.procedureKey?.length) {
      setTemplateProcedure([]);
    }
    const fetchProcedure = () => {
      try {
        const Promise1 =
          form?.procedureKey?.map((item) => {
            return SettingServices.getKeyValue(item);
          }) || [];

        Promise.all([...Promise1]).then((response) => {
          const data = response.map((item) => item.data);
          setTemplateProcedure(data);
          setForm({
            ...form,
            proceduresTemplate: {
              value: data?.map((item) => item.value[item.key]),
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchProcedure();
  }, [form?.procedureKey?.length]);

  useEffect(() => {
    let newA = [];
    form?.proceduresTemplate?.value?.map((item) => {
      newA = [...newA, ...item];
    });
    setForm({ ...form, procedures: newA });
  }, [form?.proceduresTemplate?.value?.length]);

  return (
    <>
      <Box p={4} className="flex flex-col gap-4">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">Bệnh nhân</Typography>
            <Select
              name="patientId"
              placeholder="Tên, số điện thoại, mã bệnh nhân"
              // value={form.patientId}
              className="w-full"
              options={listUser?.map((item) => ({
                label: `${item.name} (${item.phone})`,
                value: item,
              }))}
              onChange={(option) => {
                setForm({ ...form, patientId: option.value.id });
                setUserInfo(option.value);
              }}
              onInputChange={(e) => setSearch(e)}
            ></Select>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Thông tin bệnh nhân</Typography>
            <div className="w-full h-48 border rounded-sm p-4 flex flex-col gap-1">
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Tên:</p> {userInfo?.name}
              </p>
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Giới tính:</p>
                {userInfo?.sex}
              </p>
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Năm sinh:</p>
                {userInfo?.dob && moment(userInfo?.dob).format("DD-MM-YYYY")}
              </p>
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Địa chỉ:</p>
                {userInfo?.address}
              </p>
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Phone:</p>
                {userInfo?.phone}
              </p>
              <p className="flex gap-2">
                <p className="text-gray-500 w-[110px]">Tiền sử bệnh:</p>
                {userInfo?.description}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Triệu chứng - Chẩn đoán - Chỉ định
            </Typography>
            <textarea
              className="w-full h-48 border rounded-sm p-4"
              onChange={handleChangeInput}
              value={form?.description}
              name="description"
              placeholder="Những triệu chứng, chẩn đoán và chỉ định từ bác sĩ..."
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Ảnh</Typography>
            <div
              style={{ cursor: "pointer", position: "relative" }}
            >
              <UploadImage
                form={form}
                setForm={setForm}
                value={form?.images}
                onChange={(value) =>
                  setForm((prev) => ({
                    ...prev,
                    images: [...(form.images || []), value],
                  }))
                }
                imageAspect="1/1"
                folder="user"
              />
              <button className="text-[#4485cfd3] text-center border p-4 hover:bg-gray-200">
                Tải ảnh lên
              </button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Phí khám</Typography>
            <TextField
              variant="outlined"
              name="consultationFee"
              className="w-full"
              placeholder={formatCurrencyVND(50000)}
              defaultValue={500000}
              value={form?.consultationFee}
              onChange={handleChangeInput}
              helperText={formatCurrencyVND(form?.consultationFee || 50000)}
            />
          </Grid>
        </Grid>
        <Divider className="!my-2" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Đơn thuốc</Typography>
            <Select
              isMulti
              name="precription"
              placeholder="Đơn thuốc tạo sẵn"
              value={form?.precription}
              className="w-full"
              options={prescriptions}
              onChange={(option) =>
                handleChangeInput({
                  target: {
                    name: "precriptionKey",
                    value: option?.map((item) => item?.label),
                  },
                })
              }
            ></Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="w-full min-h-[300px] relative">
              <TablePrescription form={form} setForm={setForm}>
                {templatePrescription?.map((item) => (
                  <TablePrescription
                    show={item}
                    dataProps={item?.value[item?.key]}
                  />
                ))}
              </TablePrescription>
            </div>
          </Grid>
        </Grid>
        <Divider className="!my-2" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Thủ thuật</Typography>
            <Select
              isMulti
              name="procedure"
              placeholder="Thủ thuật tạo sẵn"
              value={form?.procedure}
              className="w-full"
              options={procedures}
              onChange={(option) =>
                handleChangeInput({
                  target: {
                    name: "procedureKey",
                    value: option?.map((item) => item?.label),
                  },
                })
              }
            ></Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="w-full min-h-[300px] relative">
              <TableProcedure form={form} setForm={setForm}>
                {templateProcedure?.map((item, index) => (
                  <TableProcedure
                    show={item}
                    dataProps={item?.value[item?.key]}
                  />
                ))}
              </TableProcedure>
            </div>
          </Grid>
        </Grid>
        <Divider className="!my-2" />
      </Box>
      <Box container px={4} my={2}>
        <Button onClick={handleContinue} className="!bg-blue-200 !text-black">
          Tiếp tục
        </Button>
      </Box>
    </>
  );
};

export default Create;
