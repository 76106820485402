import React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import AppSidebar from "./sidebar";
import { AppBar, IconButton, Stack } from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import { PersonOutline } from "@mui/icons-material";
import { getAuth, logout } from "store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AppLayout = ({ children }) => {
  const { role, user } = useSelector(getAuth);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/login')
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="!bg-[#20354e] w-full flex justify-between"
        sx={{ width: `calc(100% - ${240}px)`, ml: `${240}px` }}
      >
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="flex-start"
            gap={2}
            alignItems="center"
            flexGrow={1}
          >
            <Stack direction="row">
              <h4 className="font-bold">PHÒNG KHÁM LỒNG NGỰC MẠCH MÁU</h4>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={2}
            alignItems="center"
            flexGrow={1}
          >
            <Stack direction="row">
              <IconButton className="!text-white !bg-slate-700 !rounded-md">
                <PersonOutline />
                <p className="text-[14px] px-2">
                  {user?.name} ({role})
                </p>
              </IconButton>
            </Stack>
            <Stack direction="row">
              <IconButton aria-label="logout" sx={{ color: "white" }} onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <AppSidebar />
      <div style={{ width: "100%", marginTop: "60px" }}>{children}</div>
    </Box>
  );
};

export default AppLayout;
