/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    border: "1px solid #d8d8d8",
    background: "#f3f3f3",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
}));

const tableHeader = [
  { label: "STT", data: "" },
  { label: "Tên thuốc", data: "medicalMaterialName" },
  { label: "Liều lượng", data: "description" },
  { label: "Số lượng", data: "quantityPerUnit" },
];

const TableDetail = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((cell) => (
                <TableCell key={cell.data} className={classes.tableHeaderCell}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...(data?.materials || []),
            ]?.map((row, i) => (
              <TableRow key={row.id} className="border">
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>{i + 1}</Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name} fontWeight={700}>
                      {row.medicalMaterialName}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      Ngày uống {row.timesAday || "........."}  lần, mỗi lần {row.timesADrink || ".........."} viên, {row.time || "............"} ăn
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell className="border">
                  <Grid container>
                    <Typography className={classes.name}>
                      {row.quantityPerUnit} {row?.unit}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDetail;
