import {
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MTable from "./myTable";

const Update = ({
  form,
  setForm,
  handleSubmitFormUpdate,
  handleChangeInput,
}) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            disabled
            label="Bệnh"
            variant="outlined"
            name="key"
            className="w-full"
            value={form?.key}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Typography p={1} m={2} className="bg-gray-300">
          Tạo đơn thuốc
        </Typography>
        <MTable form={form} setForm={setForm} />
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextareaAutosize
            placeholder="Ghi chú"
            name="description"
            className="border border-gray-300 w-full !h-[200px] p-3 rounded-md"
            value={form?.description}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitFormUpdate}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Update;
