/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  TableHead,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AsyncSelect from "react-select/async";
import ProcedureServices from "api/medical-procedure";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: "none",
    width: "100%",
  },
  table: {
    width: "100%",
    background: "#ebebeb",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    width: "41%",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
  name: {
    color: "#146001f9",
  },
}));

const tableHeader = [
  { label: "Tên thủ thuật", data: "medicalProcedureName" },
  { label: "Ghi chú", data: "description" },
  { label: "", data: "" },
];

const TableProcedure = ({ form, setForm, show = false, children }) => {
  const [newData, setNewData] = useState({});
  const [procedures, setProcedures] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "medicalProcedureName") {
      setNewData({
        ...newData,
        medicalProcedureId: value?.id,
        medicalProcedureName: value?.name,
      });
    } else {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const addItem = () => {
    if (!newData.medicalProcedureName) {
      alert("Nhập tên thủ thuật!");
      return;
    }
    let isSame = false;
    const newArray =
      form?.procedures?.map((item) => {
        if (item.medicalProcedureName === newData.medicalProcedureName) {
          isSame = true;
          return { ...newData, description: newData.description };
        } else {
          return item;
        }
      }) || [];

    if (!isSame) {
      newArray.push(newData);
    }

    setForm({
      ...form,
      procedures: newArray || [],
    });
    setNewData(null);
    dispatch(open({ isNoti: true, message: "Đã thêm thủ thuật" }));
  };

  const deleteItem = (medicalProcedureName) => {
    const newData = form?.procedures?.filter(
      (data) => data.medicalProcedureName !== medicalProcedureName
    );
    setForm({ ...form, procedures: newData });
    setNewData();
  };

  const filterData = async (inputValue) => {
    try {
      const response = await ProcedureServices.getProceduresDoctor({
        name: inputValue,
      });
      return response.data.data?.map((item) => ({
        label: item.name,
        value: item,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterData(inputValue));
    });

  const fetchProcedure = async () => {
    try {
      const response = await ProcedureServices.getProceduresDoctor({
        name: newData?.medicalProcedureName,
      });
      setProcedures(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProcedure();
  }, [newData?.medicalProcedureName]);

  return (
    <>
      {!show ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            {!show && (
              <TableHead className="bg-slate-300">
                <TableRow>
                  {tableHeader.map((cell, i) => (
                    <TableCell key={i} className={classes.tableHeaderCell}>
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {children}
              {form?.procedures?.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell className="w-[42%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        {row.medicalProcedureName}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell className="w-[42%]">
                    <Grid container>
                      <Typography className={classes.name}>
                        {row.description}
                      </Typography>
                    </Grid>
                  </TableCell>
                  {!show ? (
                    <TableCell>
                      <Grid container>
                        <Grid item>
                          <Typography>
                            <DeleteIcon
                              color="secondary"
                              onClick={deleteItem.bind(
                                this,
                                row.medicalProcedureName
                              )}
                              className={classes.hover}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {form?.procedures?.map((row, i) => (
            <TableRow key={row.id}>
              <TableCell>
                <Grid container>
                  <Typography className={classes.name}>
                    {row.medicalProcedureName}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Typography className={classes.name}>
                    {row.description}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item>
                    <Typography>
                      <DeleteIcon
                        color="secondary"
                        onClick={deleteItem.bind(
                          this,
                          row.medicalProcedureName
                        )}
                        className={classes.hover}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
      {!show && (
        <>
          <Box className="w-full" my={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={procedures?.map((item) => ({
                    label: item.name,
                    value: item,
                  }))}
                  value={
                    newData?.medicalProcedureName
                      ? { label: newData?.medicalProcedureName }
                      : null
                  }
                  loadOptions={promiseOptions}
                  placeholder="Tên thủ thuật"
                  onChange={(option) =>
                    handleFormChange({
                      target: {
                        name: "medicalProcedureName",
                        value: { name: option.value.name, id: option.value.id },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Ghi chú"
                  onChange={handleFormChange}
                  name="description"
                  value={newData?.description || ""}
                  className="w-full"
                  defaultValue=""
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item lg={4}>
              <Typography>
                <Button
                  variant="contained"
                  onClick={addItem}
                  startIcon={<AddIcon />}
                >
                  Thêm
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TableProcedure;
