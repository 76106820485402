import axios from ".";

export const getReport = (params) =>
  axios.get("/admin/reports", {
    params: {
      ...params,
      orderBy: 'desc',
      perPage: 100,
    },
  });

export const getReportRecep = (params) =>
  axios.get("/receptionist/reports", {
    params: {
      ...params,
      orderBy: 'desc',
      perPage: 100,
    },
  });

const ReportService = {
  getReport,
  getReportRecep
};

export default ReportService;
