import axios from "../api";

export const getWarehouses = async (params) =>
  axios.get("/admin/warehouse-receipts", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const createWarehouses = async (payload) =>
  axios.post("/admin/warehouse-receipts/simple", payload);

export const deleteWarehouses = async (id) =>
  axios.delete(`/admin/warehouse-receipts/${id}`);

const WarehouseServices = {
  getWarehouses,
  createWarehouses,
  deleteWarehouses,
};

export default WarehouseServices;
