import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PrescriptionServices from "api/prescriptions";
import { useEffect, useRef, useState } from "react";
import { formatCurrencyVND } from "utils/helper";
import { Check, Print, Visibility } from "@mui/icons-material";
import Detail from "./deital";
import * as html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import moment from "moment";
import { STATUS } from "constant";
import { useReactToPrint } from "react-to-print";

const HealthRecordsReceptionist = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [rows, setRows] = useState([]);
  const [detail, setDetail] = useState();
  const [codeDetail, setCodeDetail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState({});
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "id",
      headerName: "#ID",
      width: 30,
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row.id}</span>;
      },
    },
    {
      field: "patientId",
      headerName: "Mã đơn khám",
      width: 180,
      renderCell: (params) => {
        return <span>{params.row.code}</span>;
      },
    },
    {
      field: "name",
      headerName: "Bệnh nhân",
      width: 180,
      renderCell: (params) => {
        return <span>{params.row.patient.name}</span>;
      },
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      width: 160,
      renderCell: (params) => {
        return <span>{params.row.patient.phone}</span>;
      },
    },
    {
      field: "doctorName",
      headerName: "Bác sĩ",
      width: 130,
      renderCell: (params) => {
        return <span>{params.row.doctor.name}</span>;
      },
    },
    {
      field: "consultationFee",
      headerName: "Phí sơ khám",
      width: 120,
      renderCell: (params) => {
        return <p>{formatCurrencyVND(params.row.consultationFee)}</p>;
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày khám",
      width: 130,
      renderCell: (params) => {
        return <p>{moment(params.row.createdAt).format("DD/MM/YYYY")}</p>;
      },
    },
    {
      field: "status",
      headerName: "Thanh toán",
      width: 170,
      renderCell: (params) => (
        <div
          className="w-full text-center"
          style={{
            color: STATUS[params.row.status].color,
          }}
        >
          <p className="w-32 p-1 rounded-md bg-[#dedede]">
            {STATUS[params.row.status].label}
          </p>
        </div>
      ),
    },
    {
      field: "totalFee",
      headerName: "Tổng phí",
      width: 130,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.totalFee)}</p>,
    },
    {
      field: "finalFee",
      headerName: "Phí đã sửa",
      width: 120,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.finalFee)}</p>,
    },
    {
      field: "check",
      headerName: "Xác nhận thanh toán",
      width: 160,
      renderCell: (params) => (
        <>
          {params?.row.status !== "completed" &&
            params?.row.status !== "failed" && (
              <div className="w-full flex justify-center gap-1">
                <Button onClick={() => modalConformPayment(params?.row.code)}>
                  <Check />
                </Button>
              </div>
            )}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Chi tiết",
      width: 70,
      renderCell: (params) => (
        <div className="flex gap-1">
          <Button onClick={() => handleShowDetail(params?.row)}>
            <Visibility />
          </Button>
        </div>
      ),
    },
  ];

  const handleCheckPayment = async () => {
    try {
      await PrescriptionServices.checkPrescriptions(codeDetail);
      handleClose();
      fetchPrescriptions();
      dispatch(
        open({
          isNoti: true,
          message: `Đơn khám: ${codeDetail} đã dược thanh toán`,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: `Thanh toán thất bại ${error.response?.data?.message}`,
        })
      );
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const modalConformPayment = (code) => {
    setIsOpen(true);
    setCodeDetail(code);
  };

  const handleShowDetail = (data) => {
    setDetail(data);
    setShowDrawer(true);
  };

  // const printPDF = async () => {
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("landscape", "px", [880, 540]);

  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("label.pdf");
  // };

  const printPDF = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "data",
  });

  const fetchPrescriptions = async () => {
    try {
      const response = await PrescriptionServices.getPrescriptionsReceptionist({
        perPage: 100,
        search: search?.infoUser ? search?.infoUser : null,
        doctorName: search?.doctorName ? search?.doctorName : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrescriptions();
  }, [search?.infoUser, search?.doctorName]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý đơn khám
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          value={search?.infoUser}
          onChange={(e) => setSearch({ ...search, infoUser: e.target.value })}
          label="Tìm kiếm lịch sử khám"
          placeholder="Tên, số điện thoại, mã bệnh nhân"
          className="w-[300px]"
        />
        <TextField
          value={search?.doctorName}
          onChange={(e) => setSearch({ ...search, doctorName: e.target.value })}
          label="Tìm kiếm theo tên Bác sĩ"
          placeholder="Tên Bác sĩ"
          className="w-[300px]"
        />
      </Stack>
      <Box style={{ height: "73vh", width: "82vw" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>

      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        PaperProps={{
          sx: {
            width: 1170,
          },
        }}
      >
        <Grid className="flex justify-between border border-b">
          <Typography variant="h5" className="p-3">
            Thông tin đơn khám
          </Typography>
          <Typography
            className="flex justify-center gap-2 p-5 bg-gray-200 cursor-pointer"
            onClick={printPDF}
          >
            <Print />
            <span className="!text-[16px]">Export PDF</span>
          </Typography>
        </Grid>

        <div ref={printRef}>
          <Detail data={detail} />
        </div>
      </Drawer>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Xác nhận bệnh nhân đã thanh toán"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Chọn <span className="text-green-500"> Đồng ý </span> nếu bệnh nhân
            đã thanh toán và <span className="text-red-500">Huỷ bỏ </span> nếu
            bệnh nhân chưa thanh toán
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Huỷ bỏ</Button>
          <Button onClick={handleCheckPayment} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HealthRecordsReceptionist;
