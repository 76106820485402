/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { Divider, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import ReportService from "api/reports";

const Report = () => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState({});

  const columns = [
    { field: "id", headerName: "#ID", width: 20 },
    { field: "name", headerName: "Tên", width: 200 },
    { field: "phone", headerName: "Số điện thoại", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "message", headerName: "Báo cáo", width: 350 },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 120,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const fetchReport = async () => {
    try {
      const response = await ReportService.getReport({
        name: search.name ? search.name : null,
        phone: search.phone ? search.phone : null,
        email: search.email ? search.email : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [search?.name, search?.phone, search?.email]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý báo cáo
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          value={search?.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          label="Tên"
          placeholder="Tên"
          className="w-[300px]"
        />
        <TextField
          value={search?.phone}
          onChange={(e) => setSearch({ ...search, phone: e.target.value })}
          label="Số điện thoại"
          placeholder="Số điện thoại"
          className="w-[300px]"
        />
        <TextField
          value={search?.email}
          onChange={(e) => setSearch({ ...search, email: e.target.value })}
          label="Email"
          placeholder="Email"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Report;
