import axios from "../api";

export const login = async (payload) => axios.post("/auth/login", payload);
export const register = async (payload) => axios.post("/auth/register", payload);
export const getMe = async () => axios.get("/auth/me");
export const logout = async () => {};

const AuthServices = {
	login,
	register,
	logout,
	getMe,
};

export default AuthServices;
