import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { Divider, Stack, TextField, Typography } from "@mui/material";
import PricelistServices from "api/price-list";
import moment from "moment";
import MaterialServices from "api/medical-materials";
import { formatCurrencyVND } from "utils/helper";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const defaultValue = {
  description: "",
  unit: "",
};

const Pricelist = () => {
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [materials, setMaterials] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const columns = [
    { field: "id", headerName: "#ID", width: 40 },
    {
      field: "name",
      headerName: "Tên",
      width: 170,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.name}</span>;
      },
    },
    {
      field: "type",
      headerName: "Loại",
      width: 140,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.type}</span>;
      },
    },
    {
      field: "description",
      headerName: "Ghi chú",
      width: 240,
      renderCell: (params) => {
        return <span>{params.row.medicalMaterial.description}</span>;
      },
    },
    {
      field: "sellingPricePerUnit",
      headerName: "Giá bán mỗi đơn vị",
      width: 230,
      renderCell: (params) => {
        return <span>{formatCurrencyVND(params.row.sellingPricePerUnit)}</span>;
      },
    },
    {
      field: "updatedDate",
      headerName: "Ngày nhập",
      width: 150,
      renderCell: (params) => {
        return (
          <span>{moment(params.row.updatedDate).format("DD-MM-YYYY")}</span>
        );
      },
    },
  ];

  const setDefaultForm = () => {
    setForm(defaultValue);
  };

  const fetchMaterials = async () => {
    try {
      const response = await MaterialServices.getMaterials();
      const formatArray = response.data.data.map((item) => ({
        id: item?.id,
        label: item.name,
      }));
      setMaterials(formatArray);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPricelists = async () => {
    try {
      const response = await PricelistServices.getPricelists({
        name: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    fetchPricelists();
  }, [search]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý giá
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm thuốc và vật tư"
          placeholder="Tên"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default Pricelist;
