export const INSTRUCTIONS = [
  { key: 1, label: "1 lần / ngày" },
  { key: 2, label: "2 lần / ngày" },
  { key: 3, label: "3 lần / ngày" },
  { key: 4, label: "4 lần / ngày" },
  { key: 5, label: "3 lần / tuần" },
  { key: 6, label: "4 lần / tuần" },
  { key: 7, label: "5 lần / tuần" },
];

export const TIMES = [
  { key: 0, label: "trước" },
  { key: 1, label: "sau" },
];

export const surgery = [
  {
    id: 1,
    name: "Phẫu thuật A",
    detail: [
      {
        name: "Phẫu thuật 1",
        description: "Mô tả về phẫu thuật 1",
        price: "14000000",
      },
      {
        name: "Phẫu thuật 2",
        description: "Mô tả về phẫu thuật 2",
        price: "12000000",
      },
      {
        name: "Phẫu thuật 3",
        description: "Mô tả về phẫu thuật 3",
        price: "16000000",
      },
    ],
  },
  {
    id: 2,
    name: "Phẫu thuật B",
    detail: [
      {
        name: "Phẫu thuật 4",
        description: "Mô tả về phẫu thuật 4",
        price: "18000000",
      },
      {
        name: "Phẫu thuật 5",
        description: "Mô tả về phẫu thuật 5",
        price: "11000000",
      },
      {
        name: "Phẫu thuật 6",
        description: "Mô tả về phẫu thuật 6",
        price: "20000000",
      },
    ],
  },
  // Các object còn lại tương tự
];

export const sexs = [
  { label: "Male", id: 0 },
  { label: "Female", id: 1 },
];

export const UNIT = [
  { label: "lọ", id: 0 },
  { label: "vỉ", id: 1 },
  { label: "viên", id: 2 },
  { label: "miếng dán", id: 3 },
  { label: "hộp", id: 4 },
  { label: "cái", id: 5 },
];

export const types = [
  { label: "prescription", id: 0 },
  { label: "equipment", id: 1 },
];

export const statuses = [
  { label: "completed", id: 0 },
  { label: "pending", id: 1 },
  { label: "failed", id: 3 },
];

export const STATUS = {
  completed: { color: "green", label: "Đã thanh toán" },
  failed: { color: "red", label: "Thanh toán thất bại" },
  pending: { color: "#ffa400", label: "Chưa thanh toán" },
};

export const roles = [
  { label: "admin", id: 0 },
  { label: "doctor", id: 1 },
  { label: "receptionist", id: 2 },
];

export const USER_INFO = "userInfo";
export const BASE_URL = process.env.REACT_APP_PROXY;
