import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Doctor = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/doctor/health-records");
  }, [navigate]);
  return <></>;
};

export default Doctor;
