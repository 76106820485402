import axios from "../api";

export const getUsers = async (params) =>
  axios.get("/doctor/users", { params: { page: 1, perPage: 100, ...params } });
export const getUserById = async (id) => axios.get(`/doctor/users/${id}`);

const DoctorServices = {
  getUsers,
  getUserById,
};

export default DoctorServices;
