import { Box, Button, Grid, TextField } from "@mui/material";
import UploadAvatar from "../UploadImg";
import UploadPDF from "../UploadPDF";

const Create = ({ form, handleSubmitForm, handleChangeInput, setForm }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            label="Tiêu đề"
            variant="outlined"
            name="title"
            className="w-full relative !z-50"
            value={form?.title}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tác giả"
            variant="outlined"
            name="author"
            className="w-full relative !z-50"
            value={form?.author}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={4} className="relative">
          <UploadAvatar
            value={form?.avatar}
            onChange={(value) =>
              setForm((prev) => ({
                ...prev,
                avatar: [...(form.avatar || []), value],
              }))
            }
            folder="user"
            name="avatar"
            helperText={"Ảnh đại diện"}
            classes={
              "overflow-hidden border-2 border-gray-500 border-dashed rounded-md h-[250px] flex justify-center items-center !text-xl cursor-pointer"
            }
          />
        </Grid>
        <Grid item xs={8} className="relative">
          <UploadAvatar
            value={form?.coverImg}
            onChange={(value) =>
              setForm((prev) => ({
                ...prev,
                coverImg: [...(form.coverImg || []), value],
              }))
            }
            folder="user"
            name="coverImg"
            helperText={"Ảnh bài đăng"}
            classes={
              "overflow-hidden border-2 border-gray-500 border-dashed rounded-md h-[250px] flex justify-center items-center !text-xl cursor-pointer"
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <textarea
            label={"Mô tả"}
            variant="outlined"
            name="description"
            className="w-full h-[300px] p-3 border rounded-md border-gray-300 relative !z-50"
            placeholder="Mô tả"
            type="date"
            value={form?.description || ""}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12} className="relative">
          <UploadPDF
            value={form?.filePDF}
            onChange={(value) => {
              setForm((prev) => ({
                ...prev,
                filePDF: [...(form.filePDF || []), value],
              }));
            }}
            folder="user"
            helperText={"Tải file PDF"}
            classes={
              "border border-gray-500 rounded-md flex items-center px-4 !text-[15px] h-[40px] cursor-pointer"
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label={"Ngày đăng"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="createdAt"
            className="w-full"
            type="date"
            value={form?.createdAt || ""}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitForm}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Create;
