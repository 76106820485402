import React, { useEffect } from "react";

import AppLayout from "layout";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, loginReducer, logout } from "store/authSlice";
import { USER_INFO } from "constant";
import { removeLocalStore, setUserStorage } from "utils/storage";
import AuthServices from "api/auth";

import UserAccount from "page/admin/account";
import Material from "page/admin/materials";
import Procedure from "page/admin/procedures";
import Pricelist from "page/admin/pricelist";
import Setting from "page/admin/setting";
import SettingPrescription from "page/admin/setting-prescription";
import SettingProcedure from "page/admin/setting-procedure";
import Warehouse from "page/admin/warehouse";
import NotFound from "page/404";
import Login from "page/auth/login";
import HealthRecords from "page/doctor/health-records";
import Doctor from "page/doctor";
import Admin from "page/admin";
import Receptionist from "page/reception";
import User from "page/admin/user";
import Patient from "page/reception/patient";
import HealthRecordsReceptionist from "page/reception/health-records";
import Notification from "components/notification";
import Prescription from "page/admin/prescription";
import SettingPrescriptionDoctor from "page/doctor/setting-prescription";
import SettingProcedureDoctor from "page/doctor/setting-procedure";
import Survey from "page/admin/survey";
import SurveyRecep from "page/reception/survey";
import Report from "page/admin/report";
import ReportRecep from "page/reception/report";
import Post from "page/admin/post";
import Consulation from "page/admin/consulation";
import ConsulationRecep from "page/reception/consulation";

const routesAuth = [
  {
    key: 1,
    component: Receptionist,
    path: "/receptionist",
    allowedRoles: ["receptionist"],
  },
  {
    key: 2,
    component: Admin,
    path: "/admin",
    allowedRoles: ["admin"],
  },
  {
    key: 3,
    component: Doctor,
    path: "/doctor",
    allowedRoles: ["doctor"],
  },
  {
    key: 4,
    component: Patient,
    path: "/receptionist/patient",
    allowedRoles: ["receptionist"],
  },
  {
    key: 5,
    component: User,
    path: "/admin/user/patient",
    allowedRoles: ["admin"],
  },
  {
    key: 6,
    component: UserAccount,
    path: "/admin/user/account",
    allowedRoles: ["admin"],
  },
  {
    key: 7,
    component: Material,
    path: "/admin/material/product",
    allowedRoles: ["admin"],
  },
  {
    key: 8,
    component: Procedure,
    path: "/admin/tips",
    allowedRoles: ["admin"],
  },
  {
    key: 9,
    component: Warehouse,
    path: "/admin/material/count",
    allowedRoles: ["admin"],
  },
  {
    key: 10,
    component: Pricelist,
    path: "/admin/material/price-list",
    allowedRoles: ["admin"],
  },
  {
    key: 11,
    component: Setting,
    path: "/admin/setting",
    allowedRoles: ["admin"],
  },
  {
    key: 12,
    component: SettingPrescription,
    path: "/admin/setting/prescription",
    allowedRoles: ["admin"],
  },
  {
    key: 13,
    component: SettingProcedure,
    path: "/admin/setting/procedure",
    allowedRoles: ["admin"],
  },
  {
    key: 14,
    component: HealthRecords,
    path: "/doctor/health-records",
    allowedRoles: ["doctor"],
  },
  {
    key: 15,
    component: HealthRecordsReceptionist,
    path: "/receptionist/health-records",
    allowedRoles: ["receptionist"],
  },
  {
    key: 16,
    component: Prescription,
    path: "/admin/prescriptions",
    allowedRoles: ["admin"],
  },
  {
    key: 17,
    component: SettingPrescriptionDoctor,
    path: "/doctor/setting/prescription",
    allowedRoles: ["doctor"],
  },
  {
    key: 18,
    component: SettingProcedureDoctor,
    path: "/doctor/setting/procedure",
    allowedRoles: ["doctor"],
  },
  {
    key: 19,
    component: Survey,
    path: "/admin/survey",
    allowedRoles: ["admin"],
  },
  {
    key: 20,
    component: SurveyRecep,
    path: "/receptionist/survey",
    allowedRoles: ["receptionist"],
  },
  {
    key: 21,
    component: Report,
    path: "/admin/report",
    allowedRoles: ["admin"],
  },
  {
    key: 22,
    component: ReportRecep,
    path: "/receptionist/report",
    allowedRoles: ["receptionist"],
  },
  {
    key: 23,
    component: Post,
    path: "/admin/post",
    allowedRoles: ["admin"],
  },
  {
    key: 24,
    component: Consulation,
    path: "/admin/consulation",
    allowedRoles: ["admin"],
  },
  {
    key: 25,
    component: ConsulationRecep,
    path: "/receptionist/consulation",
    allowedRoles: ["receptionist"],
  },
];

const AppRouter = () => {
  const { isLogin, role } = useSelector(getAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function checkAuth() {
    const auth = localStorage.getItem("userInfo") || "{}";
    const userInfo = JSON.parse(auth);
    if (!userInfo?.token) {
      navigate(`/login`);
      return;
    }
    try {
      const response = await AuthServices.getMe();
      if (response?.data?.id) {
        const userStorage = {
          ...userInfo,
          user: response.data || null,
        };
        dispatch(
          loginReducer({
            isLogin: true,
            user: response.data,
            role: response.data.role,
          })
        );
        setUserStorage(USER_INFO, userStorage);
      } else {
        dispatch(logout());
        removeLocalStore(USER_INFO);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLogin ? (
        <AppLayout>
          <Routes>
            {routesAuth?.map((route, index) => {
              const { path, component, allowedRoles } = route;
              if (allowedRoles.includes(role) || role === "admin") {
                return (
                  <Route key={index} exact path={path} Component={component} />
                );
              }

              return null;
            })}
            <Route
              path="/*"
              exact
              element={<Navigate to="/comingup" />}
              Component={NotFound}
            />
          </Routes>
        </AppLayout>
      ) : (
        <Routes>
          <Route key={0} exact path="/login" Component={Login} />
        </Routes>
      )}
      <Notification />
    </>
  );
};

export default AppRouter;
