import { Box, Button, Grid, TextField, TextareaAutosize } from "@mui/material";
import { formatCurrencyVND } from "utils/helper";

const Create = ({ form, handleSubmitForm, handleChangeInput }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
           
            label="Tên"
            variant="outlined"
            name="name"
            className="w-full"
            value={form?.name}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label="Giá"
            variant="outlined"
            name="currentSellingPricePerUnit"
            className="w-full"
            type="number"
            value={form?.currentSellingPricePerUnit}
            onChange={handleChangeInput}
            helperText={form?.currentSellingPricePerUnit && formatCurrencyVND(form?.currentSellingPricePerUnit)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextareaAutosize
            placeholder="Ghi chú"
            name="description"
            className="border border-gray-300 w-full !h-[200px] p-3 rounded-md"
            value={form?.description}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitForm}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Create;
