import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Print, Visibility } from "@mui/icons-material";
import FilterIcon from "@mui/icons-material/Filter";
import { DataGrid } from "@mui/x-data-grid";

import * as html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatCurrencyVND } from "utils/helper";
import PrescriptionServices from "api/prescriptions";
import Detail from "./deital";
import ImagesModal from "./modalImage";
import moment from "moment";
import { STATUS } from "constant";
import { useReactToPrint } from "react-to-print";

const Prescription = () => {
  const [showDrawerDetail, setShowDrawerDetail] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listImg, setListImg] = useState([]);
  const [rows, setRows] = useState([]);
  const [detail, setDetail] = useState();
  const [search, setSearch] = useState("");
  const printRef = useRef(null);

  const columns = [
    {
      field: "id",
      headerName: "#ID",
      width: 40,
    },
    {
      field: "code",
      headerName: "Mã đơn khám",
      width: 180,
    },
    {
      field: "name",
      headerName: "Bệnh nhân",
      width: 180,
      renderCell: (params) => {
        return <p>{params.row.patient?.name}</p>;
      },
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      width: 140,
      renderCell: (params) => {
        return <p>{params.row.patient?.phone}</p>;
      },
    },
    {
      field: "doctorName",
      headerName: "Bác sĩ khám",
      width: 140,
      renderCell: (params) => {
        return <p>{params.row.doctor?.name}</p>;
      },
    },
    {
      field: "images",
      headerName: "Ảnh",
      width: 70,
      renderCell: (params) => {
        return (
          <button
            className="p-1 rounded-md text-blue-400 hover:bg-gray-200"
            onClick={() => handleOpenModalImg(params.row.images)}
          >
            <FilterIcon />
          </button>
        );
      },
    },
    {
      field: "consultationFee",
      headerName: "Phí sơ khám",
      width: 120,
      renderCell: (params) => {
        return <p>{formatCurrencyVND(params.row.consultationFee)}</p>;
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày khám",
      width: 130,
      renderCell: (params) => {
        return <p>{moment(params.row.createdAt).format("DD/MM/YYYY")}</p>;
      },
    },
    {
      field: "status",
      headerName: "Thanh toán",
      width: 170,
      renderCell: (params) => (
        <div
          className="w-full text-center"
          style={{
            color: STATUS[params.row.status].color,
          }}
        >
          <p className="w-32 p-1 rounded-md bg-[#dedede]">
            {STATUS[params.row.status].label}
          </p>
        </div>
      ),
    },
    {
      field: "totalFee",
      headerName: "Tổng phí",
      width: 120,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.totalFee)}</p>,
    },
    {
      field: "finalFee",
      headerName: "Phí đã sửa",
      width: 120,
      renderCell: (params) => <p>{formatCurrencyVND(params.row.finalFee)}</p>,
    },
    {
      field: "action",
      headerName: "Chi tiết",
      width: 70,
      renderCell: (params) => (
        <div className="flex gap-1">
          <Button onClick={() => handleShowDetail(params.row)}>
            <Visibility />
          </Button>
        </div>
      ),
    },
  ];

  const handleOpenModalImg = (images) => {
    setListImg(images);
    setOpenModal(true);
  };

  const handleShowDetail = (data) => {
    setDetail(data);
    setShowDrawerDetail(true);
  };

  // const printPDF = async () => {
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("landscape", "px", [880, 540]);

  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("label.pdf");
  // };

  const printPDF = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "data",
  });

  const fetchPrescriptions = async () => {
    try {
      const response = await PrescriptionServices.getPrescriptionsAdmin({
        perPage: 100,
        search: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrescriptions();
  }, [search]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý đơn khám
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <TextField
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm lịch sử khám"
          placeholder="Tên, số điện thoại, mã bệnh nhân"
          className="w-[300px]"
        />
      </Stack>

      <Box style={{ height: "73vh", maxWidth: "83vw", overflow: "scroll" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
        />
      </Box>

      <Drawer
        anchor="right"
        open={showDrawerDetail}
        onClose={() => setShowDrawerDetail(false)}
        PaperProps={{
          sx: {
            width: 1170,
          },
        }}
      >
        <Grid className="flex justify-between border border-b">
          <Typography variant="h5" className="p-3">
            Thông tin đơn khám
          </Typography>
          <Typography
            className="flex justify-center gap-2 p-5 bg-gray-200 cursor-pointer"
            onClick={printPDF}
          >
            <Print />
            <span className="!text-[16px]">Export PDF</span>
          </Typography>
        </Grid>

        <div ref={printRef}>
          <Detail data={detail} />
        </div>
      </Drawer>
      <ImagesModal list={listImg} open={openModal} setOpen={setOpenModal} />
    </Box>
  );
};

export default Prescription;
