import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { formatCurrencyVND } from "utils/helper";

const Create = ({ form, materials, handleSubmitForm, handleChangeInput }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <Autocomplete
            name="medicalMaterialId"
            className="w-full"
            disablePortal
            options={materials}
            inputValue={form?.medicalMaterialId}
            onInputChange={(_, e) => {
              const item = materials?.filter((item) => item.label === e)[0];
              handleChangeInput({
                target: {
                  name: "unit",
                  value: item.value.unit,
                },
              });
              handleChangeInput({
                target: {
                  name: "medicalMaterialId",
                  value: item.id,
                },
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tên" name="medicalMaterialId" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            label="Giá nhập"
            variant="outlined"
            name="receivedPrice"
            className="w-full"
            type="number"
            value={form?.receivedPrice}
            onChange={handleChangeInput}
            helperText={
              (form?.receivedPrice && formatCurrencyVND(form?.receivedPrice)) ||
              ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Số lượng mỗi đơn vị"
            variant="outlined"
            name="quantityPerUnit"
            className="w-full"
            type="number"
            value={form?.quantityPerUnit}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            disabled
            label="Đơn vị"
            variant="outlined"
            name="unit"
            className="w-full"
            value={form?.unit || ""}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label={"Ngày nhập"}
            variant="outlined"
            name="receivedDate"
            className="w-full"
            type="date"
            value={form?.receivedDate || ""}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitForm}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Create;
