/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Autocomplete,
  TableHead,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { INSTRUCTIONS } from "constant";
import SettingServices from "api/setting";
import ProcedureServices from "api/medical-procedure";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    background: "#c8c8c8",
  },
  tableHeaderCell: {
    fontWeight: "bold",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
  name: {
    color: "#0e8c47",
  },
}));

const tableHeader = [
  { label: "Tên thủ thuật", data: "medicalProcedureName" },
  { label: "Chỉ định", data: "description" },
  { label: "Số lượng", data: "quantityPerUnit" },
  { label: "", data: "" },
];

const MTable = ({ dataProps, form, setForm }) => {
  const [newData, setNewData] = useState({});
  const [procedures, setProcedures] = useState([]);
  const [data, setData] = useState(dataProps || []);
  const classes = useStyles();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "medicalProcedureName") {
      setNewData({
        ...newData,
        medicalProcedureId: value?.id,
        medicalProcedureName: value?.name,
      });
    } else {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const addItem = () => {
    const newArray = [...data, newData];
    setData(newArray);
    setForm({ ...form, procedures: { value: newArray } });
    setNewData({});
  };

  const deleteItem = (index) => {
    const newData = data.filter((data, i) => i !== index);
    setData(newData);
    setNewData();
  };

  const fetchProcedure = async () => {
    try {
      const response = await ProcedureServices.getProcedures();
      setProcedures(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSettingProcedure = async () => {
    try {
      const response = await SettingServices.getDataWithKey(form.key);
      setData(response.data.value[form.key]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setForm({ ...form, procedures: { value: data } });
  }, [data]);

  useEffect(() => {
    fetchProcedure();
  }, []);

  useEffect(() => {
    fetchSettingProcedure();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ boxShadow: "none", padding: "0px 15px" }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((cell) => (
                <TableCell key={cell.data} className={classes.tableHeaderCell}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Grid container>
                    <Typography className={classes.name}>
                      {row.medicalProcedureName}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Typography className={classes.name}>
                      {row.description}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Typography className={classes.name}>
                      {row.quantityPerUnit} {row?.unit}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item>
                      <Typography>
                        <DeleteIcon
                          color="secondary"
                          onClick={deleteItem.bind(this, i)}
                          className={classes.hover}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box className="w-full" my={4}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                className="w-full"
                disablePortal
                options={procedures?.map((item) => item.name)}
                value={newData?.medicalProcedureName || ""}
                onInputChange={(_, e) =>
                  handleFormChange({
                    target: {
                      name: "medicalProcedureName",
                      value: procedures?.filter((item) => item.name === e)[0],
                    },
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tên thủ thuật"
                    name="medicalProcedureName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Chỉ định"
                name="description"
                onChange={handleFormChange}
                value={newData?.description || ""}
                className="w-full"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Số lượng"
                onChange={handleFormChange}
                name="quantityPerUnit"
                value={newData?.quantityPerUnit || 1}
                className="w-full"
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item lg={4}>
            <Typography>
              <Button
                variant="contained"
                onClick={addItem}
                startIcon={<AddIcon />}
              >
                Lưu
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </TableContainer>
    </>
  );
};

export default MTable;
