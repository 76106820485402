import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { Divider, Drawer, Stack, TextField, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Create from "./create";
import Update from "./update";
import moment from "moment";
import AdminServices from "api/admin";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import { compareObjects } from "utils/helper";

const User = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({
    id: "",
    name: "",
    dob: "",
    sex: "",
    phone: "",
    address: "",
    description: "",
  });
  const [search, setSearch] = useState({});
  const [oldData, setOldData] = useState({});
  const dispatch = useDispatch();

  const columns = [
    { field: "id", headerName: "#ID", width: 40 },
    { field: "code", headerName: "Mã bệnh nhân", width: 160 },
    { field: "name", headerName: "Họ và tên", width: 160 },
    {
      field: "phone",
      headerName: "Điện thoại",
      width: 140,
    },
    {
      field: "sex",
      headerName: "Giới tính",
      width: 110,
      renderCell: (params) => {
        return params.row.sex === "Male" ? <span>Nam</span> : <span>Nữ</span>;
      },
    },
    {
      field: "dob",
      headerName: "Ngày sinh",
      width: 110,
      renderCell: (params) => {
        return <span>{moment(params.row.dob).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      field: "address",
      headerName: "Địa chỉ",
      width: 200,
    },

    {
      field: "edit",
      headerName: "Sửa",
      width: 100,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEdit(params.row)} className="w-full">
            <Edit />
          </Button>
        );
      },
    },
  ];

  const handleShowDrawer = () => {
    setForm({});
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleEdit = (data) => {
    const Data = {
      ...data,
      password: undefined,
      dob: moment(data?.dob).format("YYYY-MM-DD"),
    };
    setForm(Data);
    setOldData(Data);
    setShowDrawerUpdate(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const date = new Date(form?.dob);
      const isoDateString = date.toISOString();
      const payload = {
        ...form,
        dob: isoDateString,
        role: "patient",
      };
      await AdminServices.createUserAccounts(payload);
      setShowDrawerCreate(false);
      setForm({});
      fetchUser();
      dispatch(open({ isNoti: true, message: "Tạo bệnh nhân thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitUpdate = async () => {
    try {
      const date = new Date(form?.dob);
      const isoDateString = date.toISOString();
      const newData = compareObjects(oldData, form);
      const payload = {
        ...newData,
        dob: isoDateString,
      };
      await AdminServices.updateUserAccounts(form.id, payload);
      setShowDrawerUpdate(false);
      setForm({});
      fetchUser();
      dispatch(
        open({ isNoti: true, message: "Cập nhật bệnh nhân thành công" })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const fetchUser = async () => {
    try {
      const response = await AdminServices.getPatients({
        name: search.name ? search.name : null,
        phone: search.phone ? search.phone : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [search?.name, search?.phone]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý bệnh nhân
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm bệnh nhân
        </Button>
        <TextField
          value={search?.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          label="Tìm kiếm bệnh nhân"
          placeholder="Tên"
          className="w-[300px]"
        />
        <TextField
          value={search?.phone}
          onChange={(e) => setSearch({ ...search, phone: e.target.value })}
          label="Số điên thoại"
          placeholder="sdt"
          className="w-[300px]"
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin Bệnh nhân
        </Typography>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chỉnh sửa thông tin Bệnh nhân
        </Typography>
        <Update
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
        />
      </Drawer>
      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default User;
