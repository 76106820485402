import { FormHelperText } from "@mui/material";
import uploadService from "api/upload";

const UploadAvatar = ({
  value,
  onChange,
  folder,
  disabled = false,
  error,
  helperText,
  classes,
  name,
}) => {
  const handleImageChange = async (e) => {
    const file = e.target.files?.length && e.target.files[0];
    if (file && /^image\//.test(file.type)) {
      const formData = new FormData();
      formData.append("folder", folder);
      formData.append("file", file);

      const { data } = await uploadService.uploadFile(formData);
      if (data?.location) {
        onChange(data?.location);
      }
    }
  };

  return (
    <>
      <div>
        <input
          disabled={disabled}
          type="file"
          accept="image/*"
          name={name}
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: "pointer",
          }}
        />
      </div>
      <FormHelperText error={error} className={classes}>
        {value ? (
          <div className="w-full flex gap-4">
            <img
              src={Array.isArray(value) ? value[value.length - 1] : value}
              style={{
                aspectRatio: "1/1",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              alt=""
            />
          </div>
        ) : (
          helperText
        )}
      </FormHelperText>
    </>
  );
};

export default UploadAvatar;
