/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { ReactComponent as Logo } from "assets/logo.svg";
import { Call, Language, LocationOn } from "@mui/icons-material";
import { formatCurrencyVND } from "utils/helper";
import readNumber from "read-vn-number";
const PrintReceipt2 = ({ data }) => {
  return (
    <Container>
      <Box p={6} py={16}>
        <Grid container spacing={4} className="flex justify-center gap-10">
          <div className="h-36 w-36">
            <Logo />
          </div>
          <div>
            <p className="flex flex-col text-center">
              <b className="text-[19px]">
                PHÒNG KHÁM CHUYÊN GIA MẠCH MÁU - LỒNG NGỰC
              </b>
              <b className="text-[19px]">TS BS LÊ PHI LONG</b>
            </p>
            <div className="flex flex-col gap-2 mt-2">
              <p className="flex gap-2">
                <span>
                  <LocationOn fontSize="small" />
                </span>
                <span>44 Ký Hoà, Phường 11, Quận 5, TP Hồ Chí Minh</span>
              </p>
              <p className="flex gap-2">
                <span>
                  <Call fontSize="small" />
                </span>
                <span>0567447799</span>
              </p>
              <p className="flex gap-2">
                <span>
                  <Language fontSize="small" />
                </span>
                <span>https://bsphilong.com/</span>
              </p>
            </div>
          </div>
        </Grid>
        <Typography
          variant="h5"
          fontWeight={700}
          className="w-full text-center pt-12"
        >
          BIÊN NHẬN
        </Typography>
        <Grid container spacing={4} m={1}>
          <Grid container spacing={4} mt={4}>
            <Grid xs={7}>
              <p className="flex gap-4 text-[18px] my-1">
                <span className="font-bold whitespace-nowrap">
                  Mã bệnh nhân:
                </span>
                <span>{data?.patient?.code}</span>
              </p>
              <p className="flex gap-4 text-[18px] my-1">
                <span className="font-bold whitespace-nowrap">Bệnh nhân: </span>
                <span>{data?.patient?.name}</span>
              </p>
              <p className="flex gap-4 text-[18px] my-1">
                <span className="font-bold whitespace-nowrap">Địa chỉ: </span>
                <span>{data?.patient?.address}</span>
              </p>
            </Grid>
            <Grid xs={3}>
              <p className="flex gap-4 text-[18px] my-1">
                <span className="font-bold whitespace-nowrap">Ngày sinh: </span>
                <span>{moment(data?.patient?.dob).format("DD/MM/YYYY")}</span>
              </p>
              <p className="flex gap-4 text-[18px] my-1">
                <span className="font-bold whitespace-nowrap">
                  Số điện thoại:
                </span>
                <span>{data?.patient?.phone}</span>
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid xs={12} mt={12} mx={1}>
            <Typography fontSize={17} className="flex">
              <span className="font-bold pr-4">
                Phí khám chữa bệnh Lồng ngực - Mạch máu:
              </span>
              <span className="flex gap-2">
                <span>{formatCurrencyVND(data?.finalFee)}</span>
                <span>({readNumber(data?.finalFee || 0)})</span>
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={8} mx={1}>
          <Grid xs={6}>
            <Typography className="!text-lg !mb-6">
              Phụ trách phòng khám
            </Typography>
            <Typography className="!ml-3 !text-lg !mb-1">
              TS BS Lê Phi Long
            </Typography>
          </Grid>
          <Grid xs={6} className="text-center">
            <Typography className="!text-lg !mb-4">
              Hồ Chí Minh, ngày {moment(data?.createdAt).format("DD")} tháng{" "}
              {moment(data?.createdAt).format("MM")} năm 20
              {moment(data?.createdAt).format("YY")}
            </Typography>
            <Typography className="!text-lg">Thu ngân</Typography>
            <Typography className="!text-lg !mt-12"></Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PrintReceipt2;
