import axios from "../api";

export const getUserAccounts = async (params) =>
  axios.get("/admin/users", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const getPatients = async (params) =>
  axios.get("/admin/users/get/patients", {
    params: { perPage: 100, page: 1, orderBy: "desc", ...params },
  });

export const createUserAccounts = async (payload) =>
  axios.post("/admin/users", payload);

export const updateUserAccounts = async (id, payload) =>
  axios.patch(`/admin/users/${id}`, payload);

const AdminServices = {
  getUserAccounts,
  createUserAccounts,
  updateUserAccounts,
  getPatients,
};

export default AdminServices;
