import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import { Divider, Drawer, Stack, TextField, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Create from "./create";
import Update from "./update";
import AdminServices from "api/admin";
import moment from "moment";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";
import { compareObjects } from "utils/helper";

const UserAccount = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [search, setSearch] = useState("");
  const [oldData, setOldData] = useState({});
  const dispatch = useDispatch();

  const columns = [
    { field: "id", headerName: "#ID", width: 50 },
    { field: "name", headerName: "Họ và tên", width: 160 },
    { field: "username", headerName: "Tên tài khoản", width: 140 },
    {
      field: "phone",
      headerName: "Điện thoại",
      width: 150,
    },
    {
      field: "role",
      headerName: "Vai Trò",
      width: 120,
    },
    {
      field: "sex",
      headerName: "Giới tính",
      width: 120,
      renderCell: (params) => {
        return params.row.sex === "Male" ? <span>Nam</span> : <span>Nữ</span>;
      },
    },
    {
      field: "address",
      headerName: "Địa chỉ",
      width: 230,
    },

    {
      field: "edit",
      headerName: "Sửa",
      width: 100,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEditUser(params.row)} className="w-full">
            <Edit />
          </Button>
        );
      },
    },
  ];

  const handleShowDrawer = () => {
    setForm({});
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleEditUser = (data) => {
    const Data = {
      ...data,
      password: undefined,
      dob: moment(data?.dob).format("YYYY-MM-DD"),
    };
    setForm(Data);
    setOldData(Data);
    setShowDrawerUpdate(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const date = new Date(form?.dob);
      const isoDateString = date?.toISOString();
      const payload = {
        ...form,
        dob: isoDateString,
      };
      await AdminServices.createUserAccounts(payload);
      setShowDrawerCreate(false);
      setForm({});
      fetchUser();
      dispatch(open({ isNoti: true, message: "Tạo tài khoản thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitUpdate = async () => {
    try {
      const date = new Date(form?.dob);
      const isoDateString = date.toISOString();
      const newData = compareObjects(oldData, form);
      const payload = {
        ...newData,
        dob: isoDateString,
      };
      await AdminServices.updateUserAccounts(form.id, payload);
      setShowDrawerUpdate(false);
      setForm({});
      fetchUser();
      dispatch(open({ isNoti: true, message: "Cập nhật khoản thành công" }));
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const fetchUser = async () => {
    try {
      const response = await AdminServices.getUserAccounts({
        name: search ? search : null,
      });
      setRows(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [search]);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý tài khoản
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm Tài khoản
        </Button>
        <TextField
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Tìm kiếm tài khoản"
          placeholder="Tên"
          className="w-[300px]"
        />
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin Tài khoản
        </Typography>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chỉnh sửa thông tin Tài khoản
        </Typography>
        <Update
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
        />
      </Drawer>
      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default UserAccount;
