import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { roles, sexs } from "constant";

const Update = ({ form, handleSubmitFormUpdate, handleChangeInput }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            label="Họ và tên"
            variant="outlined"
            name="name"
            className="w-full"
            defaultValue={""}
            value={form?.name}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tài khoản"
            variant="outlined"
            name="username"
            className="w-full"
            defaultValue={""}
            value={form?.username}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            className="w-full"
            type="password"
            defaultValue={""}
            value={form?.password}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <Autocomplete
            name="role"
            defaultValue={""}
            value={form?.role}
            inputValue={form?.role}
            onInputChange={(_, e) =>
              handleChangeInput({
                target: { name: "role", value: e.toLocaleLowerCase() },
              })
            }
            options={roles}
            renderInput={(params) => (
              <TextField {...params} label="Vai trò" name="role" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="sex"
            className="w-full"
            disablePortal
            defaultValue={""}
            value={form?.sex}
            options={sexs}
            inputValue={form?.sex}
            onInputChange={(_, e) =>
              handleChangeInput({ target: { name: "sex", value: e } })
            }
            renderInput={(params) => (
              <TextField {...params} label="Giới Tính" name="sex" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <TextField
            label="Số điện thoại"
            variant="outlined"
            name="phone"
            className="w-full"
            defaultValue={""}
            value={form?.phone}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            placeholder="Ngày sinh"
            type="date"
            variant="outlined"
            name="dob"
            className="w-full"
            value={form.dob}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <TextField
            label="Điạ chỉ"
            variant="outlined"
            name="address"
            className="w-full"
            value={form?.address}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container className="w-full justify-end">
        <Button variant="contained" onClick={handleSubmitFormUpdate}>
          Submit
        </Button>
      </Grid>
    </Box>
  );
};

export default Update;
