/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import Create from "./create";
import Update from "./update";
import moment from "moment";
import SettingServices from "api/setting";
import { useDispatch } from "react-redux";
import { open } from "store/notificationSlice";

const SettingProcedureDoctor = () => {
  const [showDrawerCreate, setShowDrawerCreate] = useState(false);
  const [showDrawerUpdate, setShowDrawerUpdate] = useState(false);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState("");
  const dispatch = useDispatch();

  const columns = [
    { field: "id", headerName: "#ID", width: 20 },
    { field: "key", headerName: "Tên", width: 200 },
    { field: "description", headerName: "Ghi chú", width: 250 },
    {
      field: "createdAt",
      headerName: "Ngày tạo",
      width: 290,
      renderCell: (params) => {
        return <span>{moment(params.row.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      field: "detail",
      headerName: "Chi tiết thủ thuật",
      width: 140,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEdit(params.row)} className="w-full">
            <Visibility />
          </Button>
        );
      },
    },
    {
      field: "",
      headerName: "Xoá thủ thuật",
      width: 160,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => modalConformPayment(params.row.key)}
            className="w-full"
          >
            <Delete />
          </Button>
        );
      },
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const modalConformPayment = (key) => {
    setIsOpen(true);
    setKey(key);
  };

  const handleDelete = async () => {
    try {
      const newProceduresArrKey = rows?.filter((item) => item.key !== key);
      const payloadProceduresKey = {
        key: "procedures",
        value: {
          procedures: newProceduresArrKey,
        },
      };

      const payloadProceduresKeyString = {
        key: payloadProceduresKey.key,
        value: JSON.stringify(payloadProceduresKey.value),
      };

      await SettingServices.updateDataWithKeyDoctor(
        "procedures",
        payloadProceduresKeyString
      );

      await SettingServices.deleteKeyDoctor(key);
      fetchListProcedure();
      handleClose();
      dispatch(
        open({
          isNoti: true,
          message: `Đã xoá thủ thuật ${key}`,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleShowDrawer = () => {
    setForm({});
    setShowDrawerCreate(!showDrawerCreate);
  };

  const handleEdit = (data) => {
    setForm(data);
    setShowDrawerUpdate(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const key = "procedures";
    const key2 = form.key;
    if (key2?.trim() === "" || !key2) {
      alert("Tên thủ thuật không được để trống");
      return;
    }
    if (!form?.procedures) {
      alert("Chưa chọn thủ thuật");
      return;
    }
    const checkExist = rows.find((item) => item.key === form.key);
    if (checkExist) {
      alert("Tên thủ thuật đã tồn tại!");
      return;
    }
    try {
      const payloadProceduresKey = {
        key: key,
        value: {
          [key]: [
            // eslint-disable-next-line array-callback-return
            ...rows?.map((item) => {
              if (item.key !== form.key) {
                return item;
              }
            }),
            {
              id: rows.length,
              key: form.key,
              description: form.description,
            },
          ],
        },
      };

      const payloadProceduresValue = {
        key: key2,
        value: {
          [key2]: [...form.procedures.value],
        },
      };
      const payloadProceduresKeyString = {
        key: payloadProceduresKey.key,
        value: JSON.stringify(payloadProceduresKey.value),
      };
      const payloadProceduresValueString = {
        key: payloadProceduresValue.key,
        value: JSON.stringify(payloadProceduresValue.value),
      };
      await SettingServices.updateDataWithKeyDoctor(
        key,
        payloadProceduresKeyString
      );
      await SettingServices.createDataWithKeyDoctor(
        payloadProceduresValueString
      );

      setShowDrawerCreate(false);
      setForm({});
      fetchListProcedure();
      dispatch(
        open({ isNoti: true, message: "Thêm thủ thuật mẫu thành công" })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const handleFormSubmitUpdate = () => {
    const key = "procedures";
    const key2 = form.key;
    if (key2?.trim() === "" || !key2) {
      alert("Tên thủ thuật không được để trống");
      return;
    }
    try {
      const payloadProceduresKey = {
        key: key,
        value: {
          [key]: [
            // eslint-disable-next-line array-callback-return
            ...rows?.map((item) => {
              if (item.key !== form.key) {
                return item;
              } else {
                return {
                  key: form.key,
                  description: form.description,
                };
              }
            }),
          ],
        },
      };

      const payloadProceduresValue = {
        key: key2,
        value: {
          [key2]: [...form.procedures.value],
        },
      };

      const payloadProceduresKeyString = {
        key: payloadProceduresKey.key,
        value: JSON.stringify(payloadProceduresKey.value),
      };

      const payloadProceduresValueString = {
        key: payloadProceduresValue.key,
        value: JSON.stringify(payloadProceduresValue.value),
      };

      const Promise1 = SettingServices.updateDataWithKeyDoctor(
        key,
        payloadProceduresKeyString
      );

      const Promise2 = SettingServices.updateDataWithKeyDoctor(
        key2,
        payloadProceduresValueString
      );

      Promise.all([Promise1, Promise2]).then(() => {
        setShowDrawerUpdate(false);
        setForm({});
        fetchListProcedure();
      });
      dispatch(
        open({ isNoti: true, message: "Cập nhật thủ thuật mẫu thành công" })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        open({
          isNoti: true,
          message: error?.response?.data?.message
            ? error.response.data.message
            : "Yêu cầu nhập đủ các trường",
        })
      );
    }
  };

  const fetchListProcedure = async () => {
    const key = "procedures";
    try {
      const response = await SettingServices.getDataWithKeyDoctor(key);
      setRows(
        response.data.value.procedures?.map((item, index) => ({
          id: index,
          ...item,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchListProcedure();
  }, []);

  return (
    <Box p={3}>
      <Typography variant="h5" my={1} fontWeight="bold">
        Quản lý thủ thuật
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        my={2}
      >
        <Button onClick={handleShowDrawer} variant="contained">
          Thêm thủ thuật
        </Button>
      </Stack>

      <Drawer
        anchor="right"
        open={showDrawerCreate}
        onClose={handleShowDrawer}
        PaperProps={{
          sx: {
            width: 1000,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Thông tin thủ thuật
        </Typography>
        <Create
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitForm={handleFormSubmit}
          setForm={setForm}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={showDrawerUpdate}
        onClose={() => setShowDrawerUpdate(false)}
        PaperProps={{
          sx: {
            width: 1000,
          },
        }}
      >
        <Typography variant="h5" className="p-3 border border-b">
          Chi tiết thủ thuật
        </Typography>
        <Update
          form={form}
          handleChangeInput={handleFormChange}
          handleSubmitFormUpdate={handleFormSubmitUpdate}
          setForm={setForm}
        />
      </Drawer>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="w-[600px]">
          {"Xác nhận Xoá thủ thuật"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn chắc chắn
            <span className="text-red-500 px-2">Xoá</span>
            thủ thuật <span className="text-green-500 px-2">{key}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Huỷ bỏ</Button>
          <Button onClick={handleDelete} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>

      <Box style={{ height: "73vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          // checkboxSelection
        />
      </Box>
    </Box>
  );
};

export default SettingProcedureDoctor;
