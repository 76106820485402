import axios from "../api";

export const getSurvey = (params) =>
  axios.get("/admin/surveys", {
    params: {
      ...params,
      orderBy: 'desc',
      perPage: 100,
    },
  });

export const getSurveyRecep = (params) =>
  axios.get("/receptionist/surveys", {
    params: {
      ...params,
      orderBy: 'desc',
      perPage: 100,
    },
  });

const SurveyService = {
  getSurvey,
  getSurveyRecep
};

export default SurveyService;
