import axios from "../api";

export const getPost = async (key) => axios.get(`/admin/settings/${key}`);

export const createPost = async (payload) =>
  axios.post(`/admin/settings/`, payload);

export const updatePost = async (key, payload) =>
  axios.patch(`/admin/settings/${key}`, payload);

const PostServices = {
  getPost,
  createPost,
  updatePost,
};

export default PostServices;
