/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    border: "1px solid #d8d8d8",
    background: "#f3f3f3",
  },
  hover: {
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
}));

const tableHeader = [
  { label: "STT", data: "" },
  { label: "Tên thuốc và vật tư", data: "medicalMaterialName" },
  { label: "Liều lượng - Ghi chú", data: "description" },
  { label: "Số lượng", data: "quantityPerUnit" },
];

const TableDetail = ({ data }) => {
  const classes = useStyles();

  const renderEmptyRow = () => {
    return (
      <TableRow className="border">
        <TableCell className="border">
          <Grid container>
            <Typography className="opacity-0">1</Typography>
          </Grid>
        </TableCell>
        <TableCell className="border">
          <Grid container>
            <Typography className={classes.name}></Typography>
          </Grid>
        </TableCell>
        <TableCell className="border">
          <Grid container>
            <Typography className={classes.name}></Typography>
          </Grid>
        </TableCell>
        <TableCell className="border">
          <Grid container>
            <Typography className={classes.name}></Typography>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((cell) => (
                <TableCell key={cell.data} className={classes.tableHeaderCell}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <Typography fontWeight={700} className="px-2">
                Toa thuốc
              </Typography>
              {data?.prescriptionAndMedicalMaterials?.length
                ? [...(data?.prescriptionAndMedicalMaterials || [])]?.map(
                    (row, i) => (
                      <TableRow key={i} className="border">
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {i + 1}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {row.medicalMaterial?.name ||
                                row.medicalProcedure?.name}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              ngày uống {row.timesAday} lần, mỗi lần{" "}
                              {row.timesADrink} viên, {row.time} ăn |{" "}
                              {row.description}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {row.quantityPerUnit} {row?.medicalMaterial?.unit}
                            </Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                : renderEmptyRow()}
            </>
            <>
              <Typography fontWeight={700} className="px-2">
                Thủ thuật
              </Typography>
              {data?.prescriptionAndMedicalProcedures?.length
                ? [...(data?.prescriptionAndMedicalProcedures || [])]?.map(
                    (row, i) => (
                      <TableRow key={i} className="border">
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {i + 1}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {row.medicalMaterial?.name ||
                                row.medicalProcedure?.name}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {row.description}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell className="border">
                          <Grid container>
                            <Typography className={classes.name}>
                              {row.quantityPerUnit} {row?.medicalMaterial?.unit}
                            </Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                : renderEmptyRow()}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDetail;
